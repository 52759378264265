const caseTypes = {
  '': 'Show All',
  fraud_case_one: 'Top-up Frequency',
  fraud_case_two: 'Outflow Limit',
  fraud_case_three: 'Withdrawal Amount Limit',
  fraud_case_four: 'P2P Limit',
  fraud_case_five: 'Refund Limit',
  fraud_case_four_list: 'Withdrawal Account Limit (Dragonpay)',
  fraud_case_five_list: 'Withdrawal Account Limit (Instapay)',
  fraud_case_instapay: 'Top-up Account Limit (Instapay)',
  fraud_case_multi_instapay: 'Multipay (Instapay)',
};

export default caseTypes;
