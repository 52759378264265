/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from 'axios';

import DecisionLogo from './reusables/DecisionLogo';

function RemarksModal({
  show,
  handleClose,
  record,
  user,
  updateRecordState,
}) {
  const [insertedComments, setInsertedComments] = useState([]);
  // ^ ONLY used when inserting comment from url (/remarks/{uid})

  const [remarkState, setRemarkState] = useState('');
  const [loading, setLoading] = useState(false);

  const userCaseId = record['Case ID']?.data;

  const updateRemarks = () => {
    setLoading(true);
    axios.post('/api/add_user_case_remark', {
      // eslint-disable-next-line camelcase
      user_case_id: userCaseId,
      remarks: {
        comment: remarkState,
      },
    }, {
      headers: {
        id_token: user?.accessToken,
      },
    }).then(({ data }) => {
      const { remark: { firebase_result: remarks } } = data;
      // only get the first index
      if (record.index > -1) {
        updateRecordState(record.index, remarks[0]);
      } else {
        setInsertedComments([remarks[0], ...insertedComments]);
      }
      setRemarkState('');
      setLoading(false);

      // scroll up
      document.querySelector('.remarksModal .modal-body').scroll({ top: 0 });
    });
  };

  let remarks = record?.Remarks?.data;
  if (insertedComments.length > 0) {
    remarks = [...insertedComments, ...remarks];
  }

  // will probably use typescript next react/nodejs project lel
  const renderRemarks = (remarkData = null) => {
    if (!remarkData) return null;
    const {
      comment, // string - NOTE - attribute only present for comments NOT OSSH
    } = remarkData;

    const commentReturn = (
      <span>{ comment }</span>
    );

    if (comment) return commentReturn;

    const {
      other_information: otherInformation,
      decision,
      completed_investigation: {
        previous_flagged_notes: prevFlaggedNotes, // string
        type: compTypes, // array<{date: string, flag: string}>
      },
      inflow_trail: {
        issuing_bank: issuingBank, // string
        type: inflowTypes, // array<string>
      },
      kyc_details: {
        created_at: kycCreatedAt,
        monthly_inflow: kycInflow,
        name: kycName,
        nature_of_work: natureOfWork,
        source_of_funds: sourceFunds,
      },
      linkage_check: {
        linked_users: linkedUsers, // array<{uidInput: string, uidType: string}>
      },
      online_presence: {
        findings, // array<{link: string, findings: string}>
      },
      p2p_trail: {
        note: p2pNote,
        type: p2pTypes, // array<string>
      },
      payments_trail: {
        note: paymentsNote,
        type: paymentsTypes, // array<string>
      },
      shopee_shop_link: {
        findings: shopeeShopFindings, // array<{findings: string, link: string}>
      },
      sph_escalation: {
        sph_violation_info: sphViolationInfo,
        type: sphTypes, // array<{date: string, violation: string}>
      },
      top_up_details: {
        number_of_top_ups: numberOfTopUps,
        other_top_up_info: otherTopUpInfo,
        same_branch_all: sameBranchAll,
        top_up_branch: topUpBranch,
        top_up_channel: topUpChannel,
        total_amount_top_ups: totalAmountTopUps,
      },
      wallet_history: {
        wallet_violation_info: walletInfo,
        type: walletHistoryTypes, // array<{date: string, violation: string}>
      },
      withdraw_trail: {
        recipient_bank: recipientBank,
        recipient_name: recipientName,
        bank_acount_number: bankAccountNumber,
        type: witdrawTypes,
      },
    } = remarkData;

    const linkedUsersString = linkedUsers.map(({ uidInput, uidType, uidInputEntityLinkage }) => {
      const linkString = `User is linked to ${uidInput} via ${uidType.join(', ')}`;
      const entityString = `${uidInputEntityLinkage ? `Entity Linkage: ${uidInputEntityLinkage}` : ''}`;
      return `${linkString}, ${entityString}`;
    });
    const walletHistoryString = walletHistoryTypes.map(({ date, violation }) => `${date}, ${violation}`);
    const prevFlaggedString = compTypes.map(({ date, flag }) => `${date}, ${flag}`);
    const sphString = sphTypes.map(({ date, violation }) => `${date}, ${violation}`);
    const onlinePresenceString = findings.map(({ link, findings: sds }) => `${link} Findings: ${sds}`);
    const bnrs = shopeeShopFindings.map(({ link, findings: fds }) => `${link} Findings: ${fds}`);

    // i don't get why our stakeholder prefers dis format lol

    const iTypesString = inflowTypes.join(', ');
    const wTypesString = witdrawTypes.join(', ');

    // if top up n/a, hide string
    // looks so jank am sorry
    const parStringKycDetails = `
    Name: ${kycName} | Create Time: ${kycCreatedAt} | Monthly Inflow: ${kycInflow} |
    Nature of work: ${natureOfWork} | Source of funds: ${sourceFunds} |  
    `;

    const parString = `

      ${topUpChannel ? `User made ${numberOfTopUps} top ups via ${topUpChannel} | ${sameBranchAll ? 'Same' : 'Not same'} branch for all |
      Branch ${topUpBranch} amounting to ${totalAmountTopUps} | More top up information: ${otherTopUpInfo} |` : ''}

      ${(issuingBank || iTypesString.length > 0) ? `Inflow from ${issuingBank} via ${iTypesString} |` : ''}
      
      Withdrawal ${wTypesString} ${recipientName ? `: ${recipientName}` : ''} via ${recipientBank} ${bankAccountNumber ? `with Account Number ${bankAccountNumber}` : ''} |
      
      ${(p2pTypes.length > 0 || p2pNote) ? `P2P transfer to ${p2pTypes.join(', ')} ${p2pNote ? `with notes ${p2pNote}` : ''} |` : ''} 
      
      ${(paymentsTypes.length > 0 || paymentsNote) ? `Used funds for ${paymentsTypes.join(', ') || 'N/A'} ${paymentsNote ? `with notes ${paymentsNote}` : ''} |` : ''}

      ${linkedUsersString.join('| ')} | 
      
      ${walletInfo} ${walletHistoryString.join(', ')} | 
      
      ${prevFlaggedNotes} ${prevFlaggedString.join(', ')} |
      
      ${sphViolationInfo} ${sphString.join(', ')} |
      
      ${onlinePresenceString.length > 0 ? `Online Presence: ${onlinePresenceString.join(', ')} |` : ''}
      
      ${bnrs.length > 0 ? `BNRs: ${bnrs.join(', ')} |` : ''}
      
      ${otherInformation ? `Other information: ${otherInformation}` : ''}
    `;

    return (
      <>
        <p>{parStringKycDetails}</p>
        <p>{parString.replaceAll('null', 'N/A')}</p>
        <p>
          Decision:
          {' '}
          {decision.replace('_', ' ')}
        </p>
      </>
    );
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        setInsertedComments([]); // reset back to empty array
        handleClose();
      }}
      className="remarksModal"
      key={userCaseId}
    >
      <Modal.Header closeButton>
        <h2>
          Edit
          {' '}
          {record?.Username?.data}
          {' '}
          Remarks

        </h2>

      </Modal.Header>
      <Modal.Body>

        <div className="remarksModal__history">
          <div className="ossh ossh-link">
            <a href={`/ossh?id=${userCaseId}`}> OSSH Tool </a>
          </div>
          {
            remarks?.map((remarkData) => (
              <div
                className="remarksModal__history__item"
                key={remarkData.create_time}
              >
                <label>
                  By:
                  {' '}
                  {remarkData.agent?.name}
                </label>
                <br />
                {remarkData.decision && (
                  <>
                    <label>
                      <DecisionLogo decision={remarkData.decision} />
                    </label>
                    <br />
                  </>
                )}
                <label>
                  {moment(remarkData.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                </label>
                <div className="remarksModal__content">
                  { renderRemarks(remarkData) }
                </div>
                <hr />
              </div>
            ))
          }
        </div>

      </Modal.Body>

      <Modal.Footer>
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          rows={3}
          className="commentBox"
          onChange={({ target }) => {
            setRemarkState(target.value);
          }}
          value={remarkState}
        />

        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            onClick={updateRemarks}
            disabled={
            remarkState.length < 1
            || loading
          }
          >
            {loading ? <Spinner animation="border" /> : 'Submit'}
          </Button>
        </div>
      </Modal.Footer>

    </Modal>
  );
}

RemarksModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateRecordState: PropTypes.func.isRequired,
};

export default RemarksModal;
