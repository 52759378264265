import { React, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import caseTypes from '../assets/caseTypes';

const casesModal = ({
  show,
  handleClose: closeProp,
  userCases,
}) => {
  const { cases } = userCases;
  const [detailsRender, setDetailsRender] = useState(null);

  const handleClose = () => {
    setDetailsRender(null);
    closeProp();
  };

  const renderGeneralDetail = (caseInput) => {
    const { case: userCase } = caseInput;
    const { shopee_data: shopeeData } = caseInput;
    let toReturn;

    switch (userCase) {
      case 'fraud_case_one':
        toReturn = shopeeData.topups;
        break;
      case 'fraud_case_two':
        toReturn = shopeeData.outflow_percent;
        break;
      case 'fraud_case_three':
        toReturn = shopeeData.withdrawals;
        break;
      case 'fraud_case_four':
        toReturn = shopeeData.p2p_recipients;
        break;
      case 'fraud_case_five':
        toReturn = shopeeData.refunds;
        break;
      case 'fraud_case_four_list':
        toReturn = shopeeData.fraud_case_four_list;
        break;
      case 'fraud_case_five_list':
        toReturn = shopeeData.fraud_case_five_list;
        break;
      case 'fraud_case_instapay':
        toReturn = shopeeData.users;
        break;
      case 'fraud_case_multi_instapay':
        toReturn = shopeeData.fraud_case_multi_instapay;
        break;
      default:
        toReturn = '-';
    }

    return toReturn;
  };

  const renderOverview = () => (
    cases?.map((caseData) => (
      <tr
        className="casesModal__general"
        onClick={
          () => setDetailsRender(caseData.shopee_data)
        }
      >
        <th className="casesModal__details">{ caseTypes[caseData.case] }</th>
        <td>
          { renderGeneralDetail(caseData) }
        </td>
      </tr>
    ))
  );

  const renderDetails = () => {
    const detailKeys = Object.keys(detailsRender);
    return (
      detailKeys.map((detailKey) => (
        <tr className="casesModal__detailed">
          <th className="casesModal__detailed--data">{detailKey}</th>
          <td className="casesModal__detailed--data">{detailsRender[detailKey]}</td>
        </tr>
      ))
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="casesModal"
    >
      <Modal.Header closeButton>
       <h2> Assigned Cases to user </h2>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <tbody>
            {
              !detailsRender
                ? renderOverview()
                : renderDetails()
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        { detailsRender && (
          <Button
            variant="primary"
            onClick={() => {
              setDetailsRender(null);
            }}
          >
            Back
          </Button>
        )}
        {/* <Button variant="Primary" onClick={handleClose}>
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default casesModal;
