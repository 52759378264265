/* eslint-disable no-unused-vars */
import { React } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';

function OsshKYCDeals({
  handleOnChange,
  fullName,
  createDateTime,
  monthlyInflow,
  natureOfWork,
  sourceOfFunds,
}) {
  const NatureOfWork = [
    '',
    'Unemployed',
    'Academic and Researchers',
    'Administrative',
    'Arts and Creatives',
    'Construction and Engineering',
    'Facilities and Sanitation',
    'Farming,Fishery,and Forestry',
    'Fashion and Entertainment',
    'Food and Retail',
    'Finance and Accounting',
    'Freelance',
    'General Labor',
    'Government Employee',
    'Healthcare and Medical Services',
    'Hospitality and Tourism',
    'Housewife/Househusband',
    'Dependent',
    'Human Resources',
    'IT and Technical Services',
    'Legal Practice',
    'Management and Consultancy',
    'Manufacturing and Production',
    'Media and Joumalism',
    'Non-profits,charity,ad social work',
    'Online Selling',
    'Overseas Filipino Worker',
    'Retiree',
    'Sales and Services',
    'Specialized Professionals',
    'Student',
    'Transportation and Logstics',
    'Others',
  ];

  const SourceOfFunds = [
    '',
    'Allowance',
    'Business',
    'Deposits',
    'Employment',
    'Inheritance',
    'Investment',
    'Loan Proceeds',
    'Donations',
    'Pensions',
    'Remittance',
  ];

  const optionsNatureOfWork = NatureOfWork.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  const optionsSourceOfFunds = SourceOfFunds.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));
  return (
    <>
      {/* KYC Details */}
      <div className="ossh__Card ossh__Card--kycDetails">
        <div className="heading">
          <div className="title">
            <h1> KYC Details</h1>
          </div>
        </div>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>
                Name
                <span className="requiredField"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                required
                onChange={({ target }) => {
                  handleOnChange('fullName', target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>
                Create Date & Time
                <span className="requiredField"> *</span>
              </Form.Label>
              <Form.Control
                type="datetime-local"
                required
                onChange={({ target }) => {
                  handleOnChange('createDateTime', target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>
                Monthly Inflow
                <span className="requiredField"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                required
                onChange={({ target }) => {
                  handleOnChange('monthlyInflow', target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>
                Nature of Work
                <span className="requiredField"> *</span>
              </Form.Label>
              <Form.Select
                required
                onChange={({ target }) => {
                  handleOnChange('natureOfWork', target.value);
                }}
              >
                {optionsNatureOfWork}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>
                Source of Funds
                <span className="requiredField"> *</span>
              </Form.Label>
              <Form.Select
                required
                onChange={({ target }) => {
                  handleOnChange('sourceOfFunds', target.value);
                }}
              >
                {optionsSourceOfFunds}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
}

OsshKYCDeals.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  createDateTime: PropTypes.string,
  monthlyInflow: PropTypes.string,
  natureOfWork: PropTypes.string,
  sourceOfFunds: PropTypes.string,
};

OsshKYCDeals.defaultProps = {
  fullName: undefined,
  createDateTime: undefined,
  monthlyInflow: undefined,
  natureOfWork: undefined,
  sourceOfFunds: undefined,
};

export default OsshKYCDeals;
