import { React, useState } from 'react';
import {
  Button, Modal,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/images/back.svg';

function BackModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  return (

    <div className="backModal">
      <Button variant="light" className="btn--orange" onClick={handleShow}>
        <div>
          {' '}
          <Back />
          {' '}
          {' '}
          Back
          {' '}
        </div>
      </Button>
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <h1> Changes will be discarded. Are you sure you want to exit? </h1>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}> Cancel</Button>
          <Button variant="primary" onClick={() => navigate(-1)}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BackModal;
