/* eslint-disable no-unused-vars */
import React from 'react';
import Logo from '../../assets/images/Logo.png';

function NavBar() {
  return (
    <section className="navbar">
      <div className="d-flex align-items-center mb-4">
        <div className="logo-container mr-xs">
          <div className="logo">
            <img src={Logo} alt="Shopee-Logo" />
          </div>
          <h1>ShopeePay Daily Transactions Monitoring</h1>
        </div>
      </div>
    </section>
  );
}
export default NavBar;
