import React from 'react';
import Logo from '../../assets/images/Logo.png';
import BackModal from './BackModal';

function OsshHeader() {
  return (
    <section className="navbar">
      <div className="d-flex align-items-center mb-4 justify-content-between w-100">
        <div className="logo-container mr-xs">
          <div className="logo">
            <img src={Logo} alt="Shopee-Logo" />
          </div>
          <h1>One Stop ShopeePay Notes</h1>
        </div>
        <BackModal />
      </div>
      <div className="requiredFieldNote">
        NOTE:
        {' '}
        <span className="requiredField">*</span>
        {' '}
        indicates a required field
      </div>
    </section>
  );
}
export default OsshHeader;
