import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import {
  InputGroup,
  FormControl,
} from 'react-bootstrap';

function Searchbar(props) {
  const { placeholder, onChange, disabled } = props;

  return (
    <InputGroup className="searchBar">
     
      <FormControl
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="basic-addon1"
        onChange={onChange}
        disabled={disabled}
      />
    </InputGroup>
  );
}

export default Searchbar;
