/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Form, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

function OsshOthers({
  handleOnChange,
  otherPertinentInfo,
}) {
  return (
    <>
      {/* Others */}
      <div className="ossh__Card ossh__Card--others">
        <div className="heading">
          <div className="title">
            <h1>Other Pertinent Information </h1>
          </div>
        </div>

        <Row>
          <div className="commentBox">
            <Form.Control
              as="textarea"
              placeholder="..."
              style={{ height: '100px' }}
              onChange={({ target }) => {
                handleOnChange('otherPertinentInfo', target.value);
              }}
            />
          </div>
        </Row>
      </div>

      {/* End of Others */}
    </>
  );
}
OsshOthers.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  otherPertinentInfo: PropTypes.string,
};

OsshOthers.defaultProps = {
  otherPertinentInfo: undefined,
};

export default OsshOthers;
