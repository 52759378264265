/* eslint-disable no-unused-vars */
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

function OsshDecision({
  handleOnChange,
  decision,
}) {
  return (
    <>
      {/* Decision */}
      <div className="ossh__Card ossh__Card--presence">
        <div className="heading">
          <div className="title">
            <h1>Decision</h1>
          </div>
        </div>

        <Col sm={6}>
          <div className="mb-5">
            <Row>
              <span className="requiredField"> Required *</span>
              <Form>
                {['radio'].map((type) => (
                  <div
                    key={`inline-${type}`}
                    className="mb-4 d-flex flex-column"
                  >
                    <Form.Check
                      inline
                      label="Non-Fraud"
                      value="not_fraud"
                      name="decision"
                      type={type}
                      id={`inline-${type}-1`}
                      className="mb-4"
                      onChange={({ target }) => {
                        handleOnChange('decision', target.value);
                      }}
                    />
                    <Form.Check
                      inline
                      label="Fraud"
                      value="confirmed_fraud"
                      name="decision"
                      type={type}
                      id={`inline-${type}-2`}
                      className="mb-4"
                      onChange={({ target }) => {
                        handleOnChange('decision', target.value);
                      }}
                    />
                    <Form.Check
                      inline
                      label="For Monitoring"
                      value="for_monitoring"
                      name="decision"
                      type={type}
                      id={`inline-${type}-2`}
                      className="mb-4"
                      onChange={({ target }) => {
                        handleOnChange('decision', target.value);
                      }}
                    />
                    <Form.Check
                      inline
                      label="For Flagging"
                      value="for_flagging"
                      name="decision"
                      type={type}
                      id={`inline-${type}-2`}
                      className="mb-4"
                      onChange={({ target }) => {
                        handleOnChange('decision', target.value);
                      }}
                    />
                  </div>
                ))}
              </Form>
            </Row>
          </div>
        </Col>
      </div>
      {/* End of Decision */}
    </>
  );
}
OsshDecision.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  decision: PropTypes.string,
};

OsshDecision.defaultProps = {
  decision: undefined,
};
export default OsshDecision;
