/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import {
  React, useContext, useState, useEffect, useRef,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  DropdownButton,
  Dropdown,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import Table from './reusables/Table';
import Searchbar from './reusables/Searchbar';
import AppContext from './reusables/AppContext';
import CasesModal from './CasesModal';
import RemarksModal from './RemarksModal';

import caseTypes from '../assets/caseTypes';

import Pending from '../assets/images/pending.png';
import NonFraud from '../assets/images/non-fraud.png';
import Fraud from '../assets/images/fraud.png';
import PossibleFraud from '../assets/images/possible-fraud.png';
import Monitoring from '../assets/images/monitoring.png';
import Flagging from '../assets/images/flagging.png';
import RiskFilters from '../assets/images/risk-filters.png';
import KYCDeals from '../assets/images/kyc-deals.png';
import SPHAccount from '../assets/images/sph-account.png';
import Remarks from '../assets/images/remarks.png';

function Dashboard() {
  const params = useParams();
  const navigate = useNavigate();

  const disableLazy = useRef(false);
  const selectedUsersRef = useRef([]);
  const page = useRef(0); // increments to 1 once ini load of api

  const [showUserRemarks, setShowUserRemarks] = useState(-1); // index of record state
  const [userRemarks, setUserRemarks] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [offset, setOffset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [counter, setCounter] = useState(0);
  const [filters, setFilters] = useState({
    show: null,
    showRemarks: null,
    caseTable: '',
    startDate: '',
    endDate: moment().format('YYYY-MM-DD'),
    limit: 10,
    decisions: [],
    shopeeUserName: '',
    shopeeAccount: '',
    agentEmail: '',
  });

  const { user } = useContext(AppContext);

  const updateDecision = (
    { decisionInt: decision, label },
    // eslint-disable-next-line camelcase
    { user_case_id },
    index,
  ) => {
    axios.post('/api/update_user_case', {
      // eslint-disable-next-line camelcase
      user_case_id,
      decision,
    }, {
      headers: {
        id_token: user?.accessToken,
      },
    }).then(() => {
      const labelDom = document.querySelector(`#decision-${index}`);
      labelDom.innerText = label;
    });
  };

  const fetchAgents = () => {
    axios.get('/api/get_agents', {
      headers: {
        id_token: user?.accessToken,
      },
    }).then(({ data: { agents: resAgents } }) => {
      setAgents(resAgents);
    });
  };

  /**
   * @param {bool} append - whether to append record to override array
   */
  const fetchRecords = (append = false, includeOffset = true) => {
    const recordsClone = [...records];
    setLoading(true);
    const {
      agentEmail,
      caseTable,
      startDate,
      endDate,
      limit,
      decisions,
      shopeeUserName,
      shopeeAccount,
    } = filters;

    const paramsFetch = {
      agent_email: agentEmail,
      case: caseTable,
      limit,
      start_date: startDate,
      end_date: endDate,
      decisions,
      shopee_user_name: shopeeUserName,
      shopee_account: shopeeAccount,
      offset: includeOffset ? offset : {},
    };

    axios.get('/api/get_user_cases', {
      params: paramsFetch,
      headers: {
        id_token: user?.accessToken,
      },
    }).then(({ data }) => {
      const { user_cases: userCases, offset: offSetData } = data;
      setTimeout(() => {
        setLoading(false);
        document.querySelector('.table__loader').classList.remove('loading');
      }, 100);

      setOffset(offSetData);
      const recordsToSet = userCases?.map((record, indexOfFetched) => {
        const index = indexOfFetched + ((page.current - 1) * 10); // Index of item to set in state

        const { user_case_id: uId } = record;
        const keys = Object.keys(record);
        const toReturn = {};
        // build data to render for <Table />
        keys.forEach((key) => {
          let keyToUse = '';
          switch (key) {
            case 'create_time':
              keyToUse = 'Grass Date';
              break;
            case 'shopee_user_id':
              keyToUse = 'User ID';
              break;
            case 'shopee_user_name':
              keyToUse = 'Username';
              break;
            case 'agent_email':
              keyToUse = 'Assigned Agent';
              break;
          }
          if (keyToUse !== '') {
            toReturn[keyToUse] = {
              data: record[key],
              className: `td-${key}`,
            };
          }

          toReturn[''] = {
            data: (
              <input
                type="checkbox"
                selected={selectedUsersRef.current.includes(uId)}
                onChange={() => {
                  // jank fix for state always showing initial state
                  let cloned = [...selectedUsersRef.current];
                  if (cloned.includes(uId)) {
                    cloned = cloned.filter((clonedFilter) => uId !== clonedFilter);
                  } else {
                    cloned.push(uId);
                  }
                  selectedUsersRef.current = cloned;
                  setSelectedUsers(cloned);
                }}
              />
            ),
          };

          toReturn['Case ID'] = {
            data: record.user_case_id,
          };

          toReturn.Remarks = {
            data: record.remarks,
          };

          toReturn['Grass Date'] = {
            data: record.grass_date,
          };

          // placeholders
          toReturn.Accounts = {
            data: record.shopee_accounts?.join(', '),
          };
          toReturn.Status = {
            data: '',
          };
          toReturn.KYC = {
            data: '',
          };
          // special - append decision
          const decisionsArray = [
            { decisionInt: 0, label: 'Pending', classname: 'pending' },
            { decisionInt: 1, label: 'Not Fraud', classname: 'not-fraud' },
            { decisionInt: 2, label: 'Fraud', classname: 'fraud' },
            { decisionInt: 3, label: 'For Monitoring', classname: 'for-monitoring' },
            { decisionInt: 4, label: 'For Flagging', classname: 'for-flagging' },
          ];
          toReturn.Decision = {
            className: 'td-decision',
            data: (
              <Dropdown drop="start">
                <Dropdown.Toggle
                  variant="light"
                  id={`decision-${index}`}
                  drop="start"
                >
                  { record
                    .decision
                    .toLowerCase()
                    .replace('_', ' ')}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    decisionsArray.map((dec) => (
                      <Dropdown.Item
                        className={dec.classname}
                        onClick={() => {
                          updateDecision(dec, record, index);
                        }}
                      >
                        { dec.label }
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
            ),
          };

          toReturn.Actions = {
            className: 'td-data',
            data: (
              <ul>
                <li>
                  <Link
                    to={`/user_case/${record.user_case_id}`}
                  >
                    <img src={RiskFilters} />
                    Risk Filters
                  </Link>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`https://admin.wallet.airpay.com.ph/user/${record.shopee_user_id}/user_info/`}
                  >
                    <img src={KYCDeals} />
                    KYC Deals
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`https://cs.shopee.ph/portal/info/user/${record.shopee_user_id}`}
                  >
                    <img src={SPHAccount} />
                    SPH Account
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    href="#"
                    onClick={() => {
                      setShowUserRemarks(index);
                    }}
                  >
                    <img src={Remarks} />
                    Remarks
                  </a>
                </li>
              </ul>
            ),
          };
        });

        return toReturn;
      });

      if (recordsToSet.length < 1) {
        disableLazy.current = true;
      }

      if (append) {
        setRecords([...recordsClone, ...recordsToSet]);
      } else {
        setRecords(recordsToSet);
      }
    });
  };

  const setFilter = (val, key) => {
    const filtersCloned = { ...filters };
    filtersCloned[key] = val;
    setFilters(filtersCloned);
  };

  const setDecision = (decision) => {
    const { decisions: currentDec } = filters;
    let toSet = [...currentDec];
    if (toSet.includes(decision)) {
      toSet = toSet.filter((dec) => decision !== dec);
    } else {
      toSet.push(decision);
    }
    setFilter(toSet, 'decisions');
  };

  const assignToAgent = (email) => {
    axios.post('/api/assign_user_cases', {
      user_case_ids: selectedUsers,
      agent_email: email,
    }, {
      headers: {
        id_token: user?.accessToken,
      },
    }).then(() => {
      // uncheck boxes
      const els = document.querySelectorAll('.table input[type="checkbox"]');
      for (let i = 0; i < els.length; i += 1) {
        const el = els[i];
        if (el.checked) {
          let emailTd = el.parentElement;
          let whileBool = false;
          // find el of agent email
          while (!whileBool) {
            emailTd = emailTd.nextElementSibling;
            if (!emailTd || emailTd.classList.contains('td-agent_email')) {
              whileBool = true;
            }
          }
          emailTd.innerText = email;
          el.checked = false;
        }
      }
      setSelectedUsers([]);
      selectedUsersRef.current = [];
    });
  };

  useEffect(() => {
    const bod = document.querySelector('.fraudPortal');
    let localCounter = 0;
    bod.addEventListener('scroll', ({ target }) => {
      const { scrollHeight, scrollTop } = target;
      const scrollDiff = scrollHeight - scrollTop;
      if (scrollDiff - window.innerHeight < 150) {
        localCounter += 1;
        setCounter(localCounter);
      }
    });
    fetchAgents();
  }, []);

  const {
    caseTable: ct,
    startDate: sd,
    endDate: ed,
    shopeeUserName: sn,
    decisions: ds,
    agentEmail: ae,
    shopeeAccount: sa,
  } = filters;

  // filter trigger
  useEffect(() => {
    disableLazy.current = false;
    fetchRecords(false, false);
  }, [ae, ct, sd, ed, sn, ds, sa]);

  // lazy load trigger
  useEffect(() => {
    if (loading || disableLazy.current) return;
    fetchRecords(true);
    page.current += 1;
  }, [counter]);

  useEffect(() => {
    const { case_id: caseId, modal_type: modalType } = params;
    if (caseId) {
      axios.get('/api/get_user_case', {
        params: {
          user_case_id: params.case_id,
        },
        headers: {
          id_token: user?.accessToken,
        },
      }).then((res) => {
        if (modalType === 'user_case') {
          setFilter(res.data.user_case, 'show');
        } else if (modalType === 'remarks') {
          setUserRemarks(res.data.user_case);
        }
      });
    }
  }, [params.case_id]);

  const userRemarkLen = Object.keys(userRemarks).length;
  return (
    <section className="dashboard">
      <CasesModal
        show={filters.show}
        userCases={filters.show || {}}
        handleClose={() => {
          navigate('/');
          setFilter(null, 'show');
        }}
      />
      <RemarksModal
        show={userRemarkLen > 0 || (showUserRemarks > -1)}
        handleClose={() => {
          setShowUserRemarks(-1);
          setUserRemarks({});
          navigate('/');
        }}
        record={userRemarkLen > 0
          ? {
            'Case ID': {
              data: userRemarks.user_case_id,
            },
            Username: {
              data: userRemarks.shopee_user_name,
            },
            Remarks: {
              data: userRemarks.remarks,
            }, // when calling from params
          }
          : {
            ...records[showUserRemarks],
            index: showUserRemarks,
          }} // calling from props (remarks button)
        user={user}
        setShowUserRemarks={setShowUserRemarks}
        updateRecordState={(index, remark) => {
          // only allow call when opening from props
          if (userRemarkLen === 0) {
            const cloned = records;
            cloned[index].Remarks.data.unshift(remark);
            setRecords(cloned);
          }
        }}
      />
      <div className="dashboard__filter">
        <div className="dashboard__filter__container">
          <div className="dashboard__filter__date">
            <label>From</label>
            <Form.Control
              type="date"
              placeholder="Start Date"
              onChange={({ target }) => {
                setFilter(target.value, 'startDate');
              }}
              value={filters.startDate}
            />
          </div>
          <div className="dashboard__filter__date">
            <label>To</label>
            <Form.Control
              type="date"
              placeholder="End Date"
              onChange={({ target }) => {
                setFilter(target.value, 'endDate');
              }}
              value={filters.endDate}
            />
          </div>
          <div className="dashboard__filter__search">
            <label>Username</label>
            <Searchbar
              onChange={({ target }) => {
                setFilter(target.value, 'shopeeUserName');
              }}
            />
          </div>
          <div className="dashboard__filter__search">
            <label>Bank Account Identifier</label>
            <Searchbar
              onChange={({ target }) => {
                setFilter(target.value, 'shopeeAccount');
              }}
            />
          </div>
          <div className="dashboard__filter__dropdowns">
            <Dropdown variant="secondary">
              <Dropdown.Toggle className="btn btn-Secondary" id="dropdown-basic">
                {caseTypes[filters.caseTable]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                Object.keys(caseTypes).map((caseKey) => (
                  <Dropdown.Item
                    onClick={() => {
                      setFilter(caseKey, 'caseTable');
                    }}
                  >
                    {caseTypes[caseKey]}
                  </Dropdown.Item>
                ))
              }
              </Dropdown.Menu>
            </Dropdown>
            <DropdownButton title={filters.agentEmail === '' ? 'Assigned To' : filters.agentEmail} variant="Primary" className="btn btn-Primary">
              <Dropdown.Item onClick={() => {
                setFilter('', 'agentEmail');
              }}
              >
                Show All
              </Dropdown.Item>
              {
              agents.map(({ name, email }) => (
                <Dropdown.Item onClick={() => {
                  setFilter(email, 'agentEmail');
                }}
                >
                  { name }
                </Dropdown.Item>
              ))
            }
            </DropdownButton>
          </div>
        </div>

        <div className="dashboard__filter__decisions">
          <div className="decisions">
            <label className="mb-2">Decision</label>

            <div className="decisions__Container">
              <div className="decision decision--pending">
                <div className="checkbox__Container">
                  <Form.Check
                    onClick={() => {
                      setDecision(0);
                    }}
                    type="checkbox"
                    checked={filters.decisions.includes(0)}
                  />
                </div>
                <img src={Pending} />
                {' '}
                Pending
              </div>
              <div className="decision decision--non-fraud">
                <div className="checkbox__Container">
                  <Form.Check
                    onClick={() => {
                      setDecision(1);
                    }}
                    type="checkbox"
                    checked={filters.decisions.includes(1)}
                  />
                </div>
                <img src={NonFraud} />
                {' '}
                Non-Fraud
              </div>
              <div className="decision decision--fraud">
                <div className="checkbox__Container">
                  <Form.Check
                    onClick={() => {
                      setDecision(2);
                    }}
                    checked={filters.decisions.includes(2)}
                  />
                </div>
                <img src={Fraud} />
                {' '}
                Fraud
              </div>
              <div className="decision decision--monitoring">
                <div className="checkbox__Container">
                  <Form.Check
                    onClick={() => {
                      setDecision(3);
                    }}
                    type="checkbox"
                    checked={filters.decisions.includes(3)}
                  />
                </div>
                <img src={Monitoring} />
                {' '}
                For Monitoring
              </div>
              <div className="decision decision--flagging">
                <div className="checkbox__Container">
                  <Form.Check
                    onClick={() => {
                      setDecision(4);
                    }}
                    type="checkbox"
                    checked={filters.decisions.includes(4)}
                  />
                </div>
                <img src={Flagging} />
                {' '}
                For Flagging
              </div>
            </div>
          </div>

          {selectedUsers.length > 0
            && (
              <>
                <label> </label>
                <DropdownButton
                  className="dashboard__filter__dropdowns"
                  title="Assign Agent to"
                >
                  {
                agents.map(({ name, email }) => (
                  <Dropdown.Item onClick={() => {
                    assignToAgent(email);
                  }}
                  >
                    { name }
                  </Dropdown.Item>
                ))
              }
                </DropdownButton>
              </>

            )}
        </div>
      </div>
      <div className="dashboard__table">
        <Tabs
          id="dashboard-tab"
        >
          <Tab eventKey="tm" title="Daily TM">
            <Table
              data={records}
              loading={loading}
              order={[
                '',
                'Grass Date',
                'User ID',
                'Username',
                'Accounts',
                'Assigned Agent',
                'Decision',
                'Actions',
              ]}
            />
          </Tab>
          <Tab eventKey="rem" title="Remittance (disabled)" disabled />
          <Tab eventKey="merch" title="Merchant Payments (disabled)" disabled />
          <Tab eventKey="spx" title="SPX (disabled)" disabled />
        </Tabs>
      </div>
    </section>
  );
}

export default Dashboard;
