import './App.scss';
import { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import {
  getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider,
} from 'firebase/auth';
import Navbar from './components/reusables/Navbar';
import firebase from './components/reusables/Firebase';
import Dashboard from './components/Dashboard';
import AppContext from './components/reusables/AppContext';
import Ossh from './components/Ossh';

function App() {
  const [user, setUser] = useState(null);
  const provider = new GoogleAuthProvider(firebase);
  const auth = getAuth(firebase);

  const signInGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      }).catch((error) => {
        const errorMessage = error.message;
        alert(errorMessage);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (signedUser) => {
      if (!signedUser) signInGoogle();
      else setUser(signedUser);
    });
  }, []);

  return (
    <div className="fraudPortal">
      {user
      && (
      <AppContext.Provider value={{ user }}>
        <BrowserRouter>
          <div className="fraudPortal__content">
            <Routes>
              <Route path="/" element={<Navbar />} />
              <Route path="/:modal_type/:case_id" element={<Navbar />} />
            </Routes>

            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/:modal_type/:case_id" element={<Dashboard />} />
            </Routes>
          </div>
        </BrowserRouter>
        <BrowserRouter>
          <div className="ossh">
            <Routes>
              <Route path="ossh" element={<Ossh />} />
            </Routes>
          </div>
        </BrowserRouter>
      </AppContext.Provider>
      )}
    </div>
  );
}

export default App;
