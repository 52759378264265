/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Delete from '../../assets/images/delete.png';
import Plus from '../../assets/images/plus.png';

function OsshHistory({
  handleOnChange,
  walletHistory,
  sphEscalation,
  completedInvestigations,
}) {
  const [inputListWalletHistory, setInputListWalletHistory] = useState([{ date: '', violation: '' }]);
  const [inputListCompletedInvestigation, setInputListCompletedInvestigation] = useState([{ date: '', flag: '' }]);
  const [inputListSphEscalation, setInputListSphEscalation] = useState([{ date: '', violation: '' }]);

  // handle click event of the Remove button
  const handleRemoveClickWalletHistory = (index) => {
    const list = [...inputListWalletHistory];
    list.splice(index, 1);
    setInputListWalletHistory(list);
    handleOnChange('walletHistoryType', list);
  };
  const handleRemoveClickInvestigation = (index) => {
    const list = [...inputListCompletedInvestigation];
    list.splice(index, 1);
    setInputListCompletedInvestigation(list);
    handleOnChange('completedInvestigationsType', list);
  };
  const handleRemoveClickSphEscalation = (index) => {
    const list = [...inputListSphEscalation];
    list.splice(index, 1);
    setInputListSphEscalation(list);
    handleOnChange('sphEscalationType', list);
  };

  // handle click event of the Add button
  const handleAddClickWalletHistory = () => {
    setInputListWalletHistory([...inputListWalletHistory, { date: '', violation: '' }]);
  };

  const handleAddClickSphEscalation = () => {
    setInputListSphEscalation([...inputListSphEscalation, { date: '', violation: '' }]);
  };

  // handle input change
  const handleInputChangeWalletHistory = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListWalletHistory];
    list[index][name] = value;
    setInputListWalletHistory(list);
    handleOnChange('walletHistoryType', list);
  };

  const handleInputChangeCompletedInvestigations = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListCompletedInvestigation];
    list[index][name] = value;
    setInputListCompletedInvestigation(list);
    handleOnChange('completedInvestigationsType', list);
  };

  const handleInputChangeSphEscalation = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListSphEscalation];
    list[index][name] = value;
    setInputListSphEscalation(list);
    handleOnChange('sphEscalationType', list);
  };

  return (
    <>
      {/* History */}
      <div className="ossh__Card ossh__Card--history">
        <div className="heading">
          <div className="title">
            <h1>History</h1>
          </div>
        </div>

        <Col sm={6}>
          <div className="mb-5">
            <div className="mb-4">
              <h2>Wallet History</h2>
            </div>
            <Row>
              <span className="requiredField"> Required *</span>

              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-4 d-flex flex-column">
                  <Form.Check
                    inline
                    label="No Previous Wallet Violation"
                    value="No Previous Wallet Violation"
                    name="wallet-history"
                    type={type}
                    id={`inline-${type}-1`}
                    className="mb-4"
                    onChange={({ target }) => {
                      handleOnChange('walletHistory', target.value);
                    }}
                  />

                  <Form.Check
                    inline
                    label="Existing Wallet Violation"
                    value="Existing Wallet Violation"
                    name="wallet-history"
                    type={type}
                    id={`inline-${type}-1`}
                    className="mb-4"
                    onChange={({ target }) => {
                      handleOnChange('walletHistory', target.value);
                    }}
                  />
                </div>
              ))}
            </Row>
            <Row>
              {inputListWalletHistory.map((x, i) => (
                <div>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Date
                          <span className="requiredField"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          disabled={walletHistory === 'No Previous Wallet Violation'}
                          required
                          name="date"
                          value={x.date}
                          onChange={(e) => handleInputChangeWalletHistory(e, i)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Violation
                          <span className="requiredField"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled={walletHistory === 'No Previous Wallet Violation'}
                          name="violation"
                          required
                          value={x.violation}
                          onChange={(e) => handleInputChangeWalletHistory(e, i)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2} className="d-flex align-items-center">
                      <div className="actionButton__Container">
                        {inputListWalletHistory.length !== 1 && (
                        <div className="actionButton actionButton--delete">
                          <a
                            onClick={() => handleRemoveClickWalletHistory(i)}
                            className="btn btn-transparent"
                          >
                            <img src={Delete} />
                          </a>
                        </div>
                        )}
                        {inputListWalletHistory.length - 1 === i && (
                        <div className="actionButton actionButton--add">
                          <a
                            onClick={handleAddClickWalletHistory}
                            className="btn btn-transparent"
                          >
                            <img src={Plus} />
                          </a>
                        </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Row>
          </div>
        </Col>

        <Col sm={6}>
          <div className="mb-5">
            <div className="mb-4">
              <h2>Completed Investigation</h2>
            </div>
            <Row>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-4 d-flex flex-column">
                  <Form.Check
                    inline
                    label="First time to be flagged"
                    value="First time to be flagged"
                    name="completed-investigation"
                    type={type}
                    id={`inline-${type}-1`}
                    className="mb-4"
                    onChange={({ target }) => {
                      handleOnChange('completedInvestigations', target.value);
                    }}
                  />

                  <Form.Check
                    inline
                    label="Previously Flagged"
                    value="Previously Flagged"
                    name="completed-investigation"
                    type={type}
                    id={`inline-${type}-1`}
                    className="mb-4"
                    onChange={({ target }) => {
                      handleOnChange('completedInvestigations', target.value);
                    }}
                  />
                </div>
              ))}
            </Row>
            <Row>

              {inputListCompletedInvestigation.map((x, i) => {
                const flagCount = i + 1;
                const handleAddClickInvestigation = () => {
                  setInputListCompletedInvestigation([...inputListCompletedInvestigation, { date: '', flag: '' }]);
                };
                return (
                  <div>
                    <Row>
                      <Col sm={4}>
                        <Form.Group className="mb-4">
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            type="date"
                            disabled={completedInvestigations === 'First time to be flagged'}
                            name="date"
                            value={x.date}
                            onChange={(e) => handleInputChangeCompletedInvestigations(e, i)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>Flag</Form.Label>
                          <Form.Control
                            type="text"
                            key={i}
                            placeholder={`Flag ${flagCount}`}
                            value={x.flag}
                            disabled={completedInvestigations === 'First time to be flagged'}
                            onChange={(e) => handleInputChangeCompletedInvestigations(e, i)}
                            name="flag"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={2} className="d-flex align-items-center">
                        <div className="actionButton__Container">
                          {inputListCompletedInvestigation.length !== 1 && (
                            <div className="actionButton actionButton--delete">
                              <a
                                onClick={() => handleRemoveClickInvestigation(i)}
                                className="btn btn-transparent"
                              >
                                <img src={Delete} />
                              </a>
                            </div>
                          )}
                          {inputListCompletedInvestigation.length - 1 === i && (
                            <div className="actionButton actionButton--add">
                              <a
                                onClick={handleAddClickInvestigation}
                                className="btn btn-transparent"
                              >
                                <img src={Plus} />
                              </a>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Row>
          </div>
        </Col>

        <Col sm={6}>
          <div className="mb-5">
            <div className="mb-4">
              <h2>SPH Escalation</h2>
            </div>
            <Row>
              <span className="requiredField"> Required *</span>

              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-4 d-flex flex-column">
                  <Form.Check
                    inline
                    label="No previous SPH escalation"
                    value="No previous SPH escalation"
                    name="sph-escalation"
                    type={type}
                    id={`inline-${type}-1`}
                    className="mb-4"
                    onChange={({ target }) => {
                      handleOnChange('sphEscalation', target.value);
                    }}
                  />
                  <Form.Check
                    inline
                    label="Existing SPH escalation"
                    value="Existing SPH escalation"
                    name="sph-escalation"
                    type={type}
                    id={`inline-${type}-2`}
                    onChange={({ target }) => {
                      handleOnChange('sphEscalation', target.value);
                    }}
                  />
                </div>
              ))}
            </Row>
            <Row>
              {inputListSphEscalation.map((x, i) => (
                <div>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Date
                          <span className="requiredField"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          required
                          name="date"
                          value={x.date}
                          disabled={sphEscalation === 'No previous SPH escalation'}
                          onChange={(e) => handleInputChangeSphEscalation(e, i)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Violation
                          <span className="requiredField"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="violation"
                          required
                          value={x.violation}
                          disabled={sphEscalation === 'No previous SPH escalation'}
                          onChange={(e) => handleInputChangeSphEscalation(e, i)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2} className="d-flex align-items-center">
                      <div className="actionButton__Container">
                        {inputListSphEscalation.length !== 1 && (
                        <div className="actionButton actionButton--delete">
                          <a
                            onClick={() => handleRemoveClickSphEscalation(i)}
                            className="btn btn-transparent"
                          >
                            <img src={Delete} />
                          </a>
                        </div>
                        )}
                        {inputListSphEscalation.length - 1 === i && (
                        <div className="actionButton actionButton--add">
                          <a
                            onClick={handleAddClickSphEscalation}
                            className="btn btn-transparent"
                          >
                            <img src={Plus} />
                          </a>
                        </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Row>
          </div>
        </Col>
      </div>
      {/* End of History */}
    </>
  );
}

OsshHistory.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  walletHistoryType: PropTypes.array,
  walletHistory: PropTypes.string,
  completedInvestigationsType: PropTypes.array,
  completedInvestigations: PropTypes.string,
  sphEscalationType: PropTypes.array,
  sphEscalation: PropTypes.string,

};

OsshHistory.defaultProps = {
  walletHistoryType: [],
  completedInvestigationsType: [],
  sphEscalationType: [],
  completedInvestigations: undefined,
  walletHistory: undefined,
  sphEscalation: undefined,
};
export default OsshHistory;
