/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { state, useState } from 'react';
import {
  Form, Row, Col, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Plus from '../../assets/images/plus.png';
import Delete from '../../assets/images/delete.png';

function OsshLinkageCheck({
  handleOnChange,
  uid,

}) {
  const [inputListUid, setInputListUid] = useState([{ uidInput: '', uidType: [], uidInputEntityLinkage: '' }]);

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputListUid];
    list.splice(index, 1);
    setInputListUid(list);
    handleOnChange('uid', list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputListUid([...inputListUid, { uidInput: '', uidType: '', uidInputEntityLinkage: '' }]);
  };

  const uids = [
    'Bank Account',
    'Device',
    'IP Address',
    'Credit Fingerprint',
    'Phone Number',
    'Address',
  ];

  const handleInputChangeUid = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListUid];
    if (name === 'uidType') {
      let listToSet = [...list[index][name]];
      if (listToSet.includes(value)) {
        listToSet = listToSet.filter((listItem) => listItem !== value);
      } else {
        listToSet.push(value);
      }
      list[index][name] = listToSet;
    } else {
      list[index][name] = value;
    }

    setInputListUid(list);
    handleOnChange('uid', list);
  };

  return (
    <>
      {/* Linkage Check */}
      <div className="ossh__Card ossh__Card--linkageCheck">
        <div className="heading">
          <div className="title">
            <h1> Linkage Check</h1>
          </div>
        </div>
        <Row>
          <Col sm={8}>
            <Row>
              <Col sm={4}>
                <div className="addField">
                  <Button variant="light" className="p-0 mb-4 btn--orange" onClick={handleAddClick}>
                    <img src={Plus} />
                    {' '}
                    Add Linked User
                  </Button>
                </div>
              </Col>
            </Row>

          </Col>
        </Row>
        {inputListUid.map((x, i) => (
          <div className="mb-5">
            <Row className="px-3">
              <Col sm={6}>
                <Row className="px-0 d-flex justify-content-between">
                  <Col sm={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>
                        UIDs
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="uidInput"
                        value={x.uidInput}
                        onChange={(e) => handleInputChangeUid(e, i)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={2} className="d-flex align-items-center">
                    <div className="actionButton__Container">
                      {inputListUid.length !== 1 && (
                      <div className="actionButton actionButton--delete">
                        <a
                          onClick={() => handleRemoveClick(i)}
                          className="btn btn-transparent"
                        >
                          <img src={Delete} />
                        </a>
                      </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Row>
                <Col sm={6} className="mb-4">
                  <div className="checkbox">
                    {uids.map((item, index) => (
                      <Col className="d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          key={index}
                          name="uidType"
                          value={item}
                          checked={x.uidType.includes(item)}
                          onChange={(e) => { handleInputChangeUid(e, i); }}
                        />
                        {' '}
                        {item}
                      </Col>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <div className="checkbox__Container">
                        <Form.Check
                          type="checkbox"
                          checked={x.uidInputEntityLinkage}
                          name="uidInputEntityLinkage"
                          value={x.uidInputEntityLinkage
                            ? '' // reset if value exists
                            : 'Enter Linkage'}
                          onChange={(e) => handleInputChangeUid(e, i)}
                        />
                        Entity Linkage
                      </div>
                    </div>
                  </div>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      required
                      name="uidInputEntityLinkage"
                      value={x.uidInputEntityLinkage}
                      onChange={(e) => handleInputChangeUid(e, i)}
                    />
                  </Col>
                </div>
              </Row>

            </Row>
          </div>
        ))}
      </div>
      {/* End of  Linkage Check */}
    </>
  );
}
OsshLinkageCheck.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  uid: PropTypes.array,
};

OsshLinkageCheck.defaultProps = {
  uid: [],
};
export default OsshLinkageCheck;
