/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import {
  Form, Row, Col, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Delete from '../../assets/images/delete.png';
import Plus from '../../assets/images/plus.png';

function OsshPresence({
  handleOnChange,
  onlinePresence,
  bnrs,
}) {
  const [inputListOnlinePresence, setInputListOnlinePresence] = useState([{ link: '', findings: '' }]);

  const [inputListBnrs, setInputListBnrs] = useState([{ link: '', findings: '' }]);

  const handleInputChangeBnrs = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListBnrs];
    list[index][name] = value;
    setInputListBnrs(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickOnlinePresence = (index) => {
    const list = [...inputListOnlinePresence];
    list.splice(index, 1);
    setInputListOnlinePresence(list);
  };
  const handleRemoveClickBnrs = (index) => {
    const list = [...inputListBnrs];
    list.splice(index, 1);
    setInputListBnrs(list);
  };

  // handle click event of the Add button
  const handleAddClickOnlinePresence = () => {
    setInputListOnlinePresence([...inputListOnlinePresence, { link: '', findings: '' }]);
  };

  const handleAddClickBnrs = () => {
    setInputListBnrs([...inputListBnrs, { link: '', findings: '' }]);
  };

  const handleInputOnlinePresence = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListOnlinePresence];
    list[index][name] = value;
    setInputListOnlinePresence(list);
    handleOnChange('onlinePresence', list);
  };

  const handleInputBnrs = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListBnrs];
    list[index][name] = value;
    setInputListBnrs(list);
    handleOnChange('bnrs', list);
  };
  return (
    <>
      {/* Presence */}
      <div className="ossh__Card ossh__Card--history">
        <div className="heading">
          <div className="title">
            <h1>Presence</h1>
          </div>
        </div>

        <Col sm={6}>
          <div className="mb-5">
            <div className="mb-4">
              <h2>Online Presence</h2>
            </div>
            <Row>
              <Table className="table--ossh">
                <thead>
                  <tr>
                    <th>Link</th>
                    <th>Findings</th>
                  </tr>
                </thead>
                <tbody>
                  {inputListOnlinePresence.map((x, i) => (
                    <tr>
                      <td>
                        <Form.Control
                          type="text"
                          name="link"
                          required
                          value={x.link}
                          onChange={(e) => handleInputOnlinePresence(e, i)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="findings"
                          required
                          value={x.findings}
                          onChange={(e) => handleInputOnlinePresence(e, i)}
                        />
                      </td>
                      <td>
                        <div className="actionButton__Container">
                          {inputListOnlinePresence.length !== 1 && (
                          <div className="actionButton actionButton--delete">
                            <a
                              onClick={() => handleRemoveClickOnlinePresence(i)}
                              className="btn btn-transparent"
                            >
                              <img src={Delete} />
                            </a>
                          </div>
                          )}
                          {inputListOnlinePresence.length - 1 === i && (
                          <div className="actionButton actionButton--add">
                            <a
                              onClick={handleAddClickOnlinePresence}
                              className="btn btn-transparent"
                            >
                              <img src={Plus} />
                            </a>
                          </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </div>

          <div className="mb-5">
            <div className="mb-4">
              <h2>BNRS/Shopee Link (if applicable)</h2>
            </div>
            <Row>
              <Table className="table--ossh">
                <thead>
                  <tr>
                    <th>Link</th>
                    <th>Findings</th>
                  </tr>
                </thead>
                <tbody>
                  {inputListBnrs.map((x, i) => (
                    <tr>
                      <td>
                        <Form.Control
                          type="text"
                          name="link"
                          value={x.link}
                          onChange={(e) => handleInputBnrs(e, i)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="findings"
                          value={x.findings}
                          onChange={(e) => handleInputBnrs(e, i)}
                        />
                      </td>
                      <td>
                        <div className="actionButton__Container">
                          {inputListBnrs.length !== 1 && (
                          <div className="actionButton actionButton--delete">
                            <a
                              onClick={() => handleRemoveClickBnrs(i)}
                              className="btn btn-transparent"
                            >
                              <img src={Delete} />
                            </a>
                          </div>
                          )}
                          {inputListBnrs.length - 1 === i && (
                          <div className="actionButton actionButton--add">
                            <a
                              onClick={handleAddClickBnrs}
                              className="btn btn-transparent"
                            >
                              <img src={Plus} />
                            </a>
                          </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </div>
        </Col>
      </div>
      {/* End of Presence */}
    </>
  );
}

OsshPresence.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  onlinePresence: PropTypes.array,
  bnrs: PropTypes.array,
};

OsshPresence.defaultProps = {
  onlinePresence: [],
  bnrs: [],
};

export default OsshPresence;
