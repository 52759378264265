/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

function OsshMoneytrail({
  handleOnChange,
  otherInflowPartner,
  otherPayments,
  issuingBank,
  inflowTrailType,
  recipientBank,
  withdrawalTrailType,
  recipientName,
  bankAccountNumber,
  p2p,
  payments,
  p2pNotes,
  paymentNotes,
  otherWithdrawalType,

}) {
  const [checked, setChecked] = useState([]);
  const [checkedp2p, setCheckedp2p] = useState([]);
  const [checkedp2pNoSimilarInfo, setCheckedp2pNoSimilarInfo] = useState([]);
  const [checkedWithdrawalType, setCheckedWithdrawalType] = useState([]);
  const [checkedPayments, setCheckedPayments] = useState([]);

  const checkList = ['Instapay', 'DragonPay', 'Direct Debit', 'Debit Cards', 'N/A'];
  const [bankChecked, bankSetChecked] = useState(false);

  const [p2pNoSimilarInfoChecked, p2pNoSimilarInfoSetChecked] = useState(false);
  const [p2pOthersChecked, p2pOthersSetChecked] = useState(false);
  const [checkedp2pType, checkedp2pTypeSetChecked] = useState(false);
  const [withdrawalChecked, withdrawalSetChecked] = useState(false);
  const [otherWithdrawalChecked, otherWithdrawalSetChecked] = useState(false);
  const [paymentsChecked, paymentsSetChecked] = useState(false);
  const p2pOptions = [
    'Under same surname or possible relative',
    'Same address or same household',
    'Same company or employer',
  ];
  const p2pNoSimilarInfo = [
    'No Similar Information found',
  ];
  const recipient = [
    'Under same KYC verified name',
    'Under a different account name',
    'Under multiple random account names',
    'Under same surname or possible relative',
  ];

  const paymentsOptions = [
    'Digital Purchase (DP)',
    'Market Price (MP)',
    'Bills/Utilities',
    'Merchants/Payments',
    'SPLater/SLoans',
  ];

  const recipientBankOptions = [
    '',
    'Alipay Philippines, Inc.',
    'AllBank (A Thrift Bank), Inc.',
    'Asia United Bank Corporation',
    'Bangko Mabuhay (A Rural Bank), Inc.',
    'Bank of Commerce',
    'Bank of the Philippine Islands / BPI Family',
    'Bayad Center',
    'BDO  Direct Debit',
    'BDO Network Bank',
    'BDO Unibank, Inc.',
    'Binangonan Rural Bank',
    'BPI Direct BanKO, Inc., A Savings Bank',
    'BPI Direct Debit',
    'Camalig Bank, Inc. (A Rural Bank)',
    'CARD BANK Inc.',
    'Cebuana Lhuillier',
    'Cebuana Lhuillier (4K and above)',
    'Cebuana Lhuillier (below 4K)',
    'Cebuana Lhuillier Rural Bank, Inc',
    'China Bank Savings, Inc.',
    'China Banking Corporation',
    'CIMB Philippines, Inc.',
    'CIS Bayad Center, Inc. (CBCI)',
    'Community Rural Bank of Romblon, Inc.',
    'CTBC Bank (Philippines) Corporation',
    'DA5',
    'DCPay (Coins.ph)',
    'Development Bank of the Philippines',
    'Dumaguete City Development Bank, Inc.',
    'Dungganon Bank (A Microfinance Rural Bank), Inc.',
    'East West Banking Corporation',
    'East West Rural Bank',
    'Ecashpay Asia, Inc.',
    'Equicom Savings Bank, Inc.',
    'ETap',
    'EXPay',
    'Expresspay',
    'GrabPay',
    'G-Xchange, Inc.',
    'ING Bank N.V.',
    'ISLA Bank (A Thrift Bank), Inc.',
    'Land Bank of The Philippines',
    'Legazpi Savings Bank, Inc.',
    'Lulu Financial Services (Phils.) Inc',
    'Malayan Bank Savings and Mortgage Bank, Inc.',
    'Maya Bank, Inc.',
    'Maybank Philippines, Inc.',
    'Metrobank Direct Debit',
    'Metropolitan Bank and Trust Company',
    'Mindanao Consolidated Cooperative Bank',
    'MLhuillier',
    'N/A',
    'Omnipay, Inc.',
    'Partner Rural Bank (Cotabato), Inc.',
    'Pay&Go',
    'PayMaya Philippines, Inc.',
    'Philippine Bank of Communications',
    'Philippine Business Bank, Inc., A Savings Bank',
    'Philippine National Bank',
    'Philippine Savings Bank',
    'Philippine Veterans Bank',
    'PhilTrust Bank',
    'Possible',
    'PPS-PEPP Financial Services Corporation',
    'Producers Savings Bank Corporation',
    'Puregold',
    'Queen City Development Bank, Inc.',
    'Quezon Capital Rural Bank, Inc.',
    'Rizal Commercial Banking Corporation',
    'Rizal Commercial Banking Corporation IST',
    'Robinsons Bank Corporation',
    'Rural Bank of Guinobatan, Inc.',
    'Seabank Philippines, Inc.',
    'Security Bank Corporation',
    'SM Bills Payment',
    'SpeedyPay, Incorporated',
    'Standard Chartered Bank',
    'Starpay Corporation',
    'Sterling Bank of Asia, Inc., A Savings Bank',
    'Sun Savings Bank, Inc.',
    'TayoCash, Inc.',
    'True Money',
    'UCPB Savings Bank, Inc.',
    'Union Bank of the Philippines',
    'Unionbank Direct Debit',
    'United Coconut Planters Bank',
    'USSC Money Services Inc.',
    'Wealth Development Bank Corporation',
    'ZoomPay',
    'Zybi Tech Inc.',
  ];

  const dragonPay = [
    'Asia United Bank',
    'Banco de Oro',
    'Bank of the Philippine islands',
    'BPI Family Savings Bank',
    'China Banking Corporation',
    'EastWest Bank',
    'Land Bank of The Philippines',
    'Metropolitan Bank and Trust Company',
    'Philippine National Bank',
    'Rizal Commercial Banking Corporation',
    'Robinsons Bank Corporation',
    'Security Bank Corporation',
    'Union Bank of the Philippines',
    'United Coconut Planters Bank',
    'Cebuana Lhuillier (below 4K)',
    'Cebuana Lhuillier (4K and above)',
  ];

  const instaPay = [
    'Alipay Philippines, Inc.',
    'AllBank (A Thrift Bank), Inc.',
    'Asia United Bank Corporation',
    'Bangko Mabuhay (A Rural Bank), Inc.',
    'Bank of Commerce',
    'Bank of the Philippine Islands / BPI Family',
    'BDO Network Bank',
    'BDO Unibank, Inc.',
    'Binangonan Rural Bank',
    'BPI Direct BanKO, Inc., A Savings Bank',
    'Camalig Bank, Inc. (A Rural Bank)',
    'CARD BANK Inc.',
    'Cebuana Lhuillier Rural Bank, Inc',
    'China Bank Savings, Inc.',
    'China Banking Corporation',
    'CIS Bayad Center, Inc. (CBCI)',
    'Community Rural Bank of Romblon, Inc.',
    'CTBC Bank (Philippines) Corporation',
    'DCPay (Coins.ph)',
    'Development Bank of the Philippines',
    'Dumaguete City Development Bank, Inc.',
    'Dungganon Bank (A Microfinance Rural Bank), Inc.',
    'East West Banking Corporation',
    'East West Rural Bank',
    'Equicom Savings Bank, Inc.',
    'G-Xchange, Inc.',
    'GrabPay',
    'ING Bank N.V.',
    'ISLA Bank (A Thrift Bank), Inc.',
    'Land Bank of The Philippines',
    'Land Bank of The Philippines',
    'Legazpi Savings Bank, Inc.',
    'Lulu Financial Services (Phils.) Inc.',
    'Malayan Bank Savings and Mortgage Bank, Inc.',
    'Maybank Philippines, Inc.',
    'Metropolitan Bank and Trust Company',
    'Mindanao Consolidated Cooperative Bank',
    'Omnipay, Inc.',
    'Partner Rural Bank (Cotabato), Inc.',
    'PayMaya Philippines, Inc.',
    'Philippine Bank of Communications',
    'Philippine Business Bank, Inc., A Savings Bank',
    'Philippine National Bank',
    'Philippine Savings Bank',
    'Philippine Veterans Bank',
    'PhilTrust Bank',
    'Producers Savings Bank Corporation',
    'Queen City Development Bank, Inc.',
    'Quezon Capital Rural Bank, Inc.',
    'Rizal Commercial Banking Corporation IST',
    'Rizal Commercial Banking Corporation IST',
    'Robinsons Bank Corporation',
    'Rural Bank of Guinobatan, Inc.',
    'Seabank Philippines, Inc.',
    'Security Bank Corporation',
    'Standard Chartered Bank',
    'Starpay Corporation',
    'Sterling Bank of Asia, Inc., A Savings Bank',
    'Sun Savings Bank, Inc.',
    'TayoCash, Inc.',
    'UCPB Savings Bank, Inc.',
    'Union Bank of the Philippines',
    'United Coconut Planters Bank',
    'USSC Money Services Inc.',
    'Wealth Development Bank Corporation',
    'Zybi Tech Inc.',
    'Maya Bank, Inc.',
    'SpeedyPay, Incorporated',
    'CIMB Philippines, Inc.',
    'PPS-PEPP Financial Services Corporation',
    'Ecashpay Asia, Inc.',
  ];

  const directDebit = [
    'BPI Direct Debit',
    'Unionbank Direct Debit',
    'BDO  Direct Debit',
    'Metrobank Direct Debit',
  ];

  const allBanks = {
    Instapay: instaPay,
    DragonPay: dragonPay,
    'Direct Debit': directDebit,
    'Debit Cards': [],
    'N/A': [],
  };

  const combined = [''];
  inflowTrailType.forEach((sel) => {
    combined.push(...allBanks[sel]);
  });

  const distinctBankOptions = [...new Set(combined)];

  useEffect(() => {
    handleOnChange('issuingBank', null);
    // setting this here instead of end of handlecheck because of async/setstate issues
  }, [inflowTrailType]);

  useEffect(() => {
    if (!otherWithdrawalChecked) {
      handleOnChange('otherWithdrawalType', '');
    }
  }, [otherWithdrawalChecked]);

  // Add/Remove checked item from list
  const handleCheck = ({ target }) => {
    const { value } = target;
    let updatedList = [...checked];

    if (checked.includes(value)) {
      updatedList.splice(checked.indexOf(value), 1);
    } else if (value.toLowerCase() === 'n/a') {
      updatedList = ['N/A'];
    } else if (target.checked) {
      updatedList = [...checked, value];
    }
    setChecked(updatedList);
    handleOnChange('inflowTrailType', updatedList);
  };

  const handleCheckp2p = (event) => {
    let updatedList = [...checkedp2p];
    if (event.target.checked) {
      updatedList = [...checkedp2p, event.target.value];
    } else {
      updatedList.splice(checkedp2p.indexOf(event.target.value), 1);
    }
    setCheckedp2p(updatedList);
    handleOnChange('p2p', updatedList);
    checkedp2pTypeSetChecked(updatedList.length > 0);
  };
  const handleCheckp2pNoSimilarInfo = (event) => {
    let updatedList = [...checkedp2pNoSimilarInfo];
    if (event.target.checked) {
      updatedList = [...checkedp2pNoSimilarInfo, event.target.value];
    } else {
      updatedList.splice(checkedp2pNoSimilarInfo.indexOf(event.target.value), 1);
    }
    setCheckedp2pNoSimilarInfo(updatedList);
    handleOnChange('p2p', updatedList);
    p2pNoSimilarInfoSetChecked(!p2pNoSimilarInfoChecked);
  };

  const handleCheckWithdrawalType = (event) => {
    let withdrawalUpdatedList = [...checkedWithdrawalType];
    if (event.target.checked) {
      withdrawalUpdatedList = [...checkedWithdrawalType, event.target.value];
    } else {
      withdrawalUpdatedList.splice(checkedWithdrawalType.indexOf(event.target.value), 1);
    }
    setCheckedWithdrawalType(withdrawalUpdatedList);
    handleOnChange('withdrawalTrailType', withdrawalUpdatedList);
    withdrawalSetChecked(!withdrawalChecked);
  };

  const handleCheckPayments = ({ target }) => {
    const { value } = target;
    let paymentsUpdatedList = [...checkedPayments];

    if (target.checked) {
      paymentsUpdatedList = [...checkedPayments, value];
    } else {
      paymentsUpdatedList.splice(checkedPayments.indexOf(value), 1);
    }

    setCheckedPayments(paymentsUpdatedList);
    handleOnChange('payments', paymentsUpdatedList);
    paymentsSetChecked(!paymentsChecked);
  };
  // Generate string of checked items
  const checkedItems = checked.length
    ? checked.reduce((checkedItem, item) => `${checkedItem},
    ${item}`)
    : '';

  const optionsRecipientBank = recipientBankOptions.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  return (
    <>
      {/* Money Trail */}
      <div className="ossh__Card ossh__Card--moneyTrail">
        <div className="heading">
          <div className="title">
            <h1>Money Trail</h1>
          </div>
        </div>
        <Row>
          <Col>
            <div className="mb-4">
              <h2>Inflow</h2>
            </div>
            <Row>
              <Form.Group className="mb-4">
                <Form.Label>Issuing Bank</Form.Label>
                <Form.Select
                  required
                  className="mb-4"
                  disabled={bankChecked}
                  onChange={({ target }) => {
                    handleOnChange('issuingBank', target.value);
                  }}
                >
                  {
                    distinctBankOptions.map((bankOption) => (
                      <option
                        value={bankOption}
                        selected={bankOption === issuingBank}
                      >
                        {bankOption}
                      </option>
                    ))
                  }
                </Form.Select>

                {checkList.map((item, index) => (
                  <div className="d-flex align-items-center mb-3">
                    <Form.Check
                      type="checkbox"
                      key={index}
                      value={item}
                      disabled={
                        // disable if n/a is checked (unless option is n/a)
                        (item !== 'N/A' && checked.includes('N/A'))
                        || bankChecked
                      }
                      onClick={handleCheck}
                      onChange={handleCheck}
                      checked={checked.includes(item)}
                    />
                    <span>
                      {item}
                    </span>
                  </div>
                ))}
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <div className="checkbox__Container">
                      <Form.Check
                        type="checkbox"
                        required
                        value="Other Bank"
                        disabled={
                          checked.includes('N/A')
                        }
                        checked={otherInflowPartner}
                        onChange={() => {
                          handleOnChange(
                            'otherInflowPartner',
                            otherInflowPartner ? '' : 'Inflow Others',
                          );
                        }}
                      />
                      Others
                    </div>
                    <Form.Control
                      type="text"
                      value={otherInflowPartner}
                      onChange={({ target }) => {
                        handleOnChange('otherInflowPartner', target.value);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Row>
            <Row>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Row>
            <Row>
              <div className="mb-4">
                <h2>P2P</h2>
              </div>
              <Form.Group className="mb-5">
                <div className="d-flex flex-column">
                  {p2pOptions.map((item, index) => (
                    <div className="d-flex align-items-center mb-3">
                      <Form.Check
                        type="checkbox"
                        key={index}
                        value={item}
                        disabled={p2pNoSimilarInfoChecked || p2pOthersChecked}
                        onClick={handleCheckp2p}
                        onChange={handleCheckp2p}
                        checked={checkedp2p.includes(item)}
                      />
                      {item}
                    </div>
                  ))}
                  {p2pNoSimilarInfo.map((item, index) => (
                    <div className="d-flex align-items-center mb-3">
                      <Form.Check
                        type="checkbox"
                        key={index}
                        value={item}
                        onClick={handleCheckp2pNoSimilarInfo}
                        onChange={handleCheckp2pNoSimilarInfo}
                        disabled={checkedp2pType || p2pOthersChecked}
                        checked={p2pNoSimilarInfoChecked || checkedp2pNoSimilarInfo.includes(item)}
                      />
                      {item}
                    </div>
                  ))}
                  <div className="d-flex align-items-center mb-3">
                    <div className="checkbox__Container">
                      <Form.Check
                        type="checkbox"
                        value="Others"
                        disabled={p2pNoSimilarInfoChecked}
                        onChange={({ target }) => {
                          handleOnChange('p2p', [target.value]);
                          p2pOthersSetChecked(!p2pOthersChecked);
                        }}
                      />
                      Others
                    </div>
                    <Form.Control
                      type="text"
                      name="p2p"
                      value={p2pOthersChecked ? p2p[0] : ''}
                      disabled={p2pNoSimilarInfoChecked}
                      onChange={({ target }) => {
                        handleOnChange('p2p', [target.value]);
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <div className="commentBox mt-4">
                <Form.Label>P2P Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="..."
                  style={{ height: '100px' }}
                  onChange={({ target }) => {
                    handleOnChange('p2pNotes', target.value);
                  }}
                />
              </div>
            </Row>
          </Col>

          <Col>
            <div className="mb-4">
              <h2>Withdrawal</h2>
            </div>
            <Row>
              <Form.Group className="mb-4">
                <Form.Label>Recipient Bank</Form.Label>
                <Form.Select
                  name="recipientBank"
                  className="mb-4"
                  onChange={({ target }) => {
                    handleOnChange('recipientBank', target.value);
                  }}
                >
                  {optionsRecipientBank}
                </Form.Select>
                <div className="d-flex flex-column">
                  {recipient.map((item, index) => (
                    <div className="d-flex align-items-center mb-3">
                      <Form.Check
                        type="checkbox"
                        key={index}
                        value={item}
                        onClick={handleCheckWithdrawalType}
                        onChange={handleCheckWithdrawalType}
                        checked={checkedWithdrawalType.includes(item)}
                      />
                      <span>
                        {item}
                      </span>
                    </div>
                  ))}
                  <div className="d-flex align-items-center mb-4">
                    <div className="checkbox__Container">
                      <Form.Check
                        type="checkbox"
                        value="Others"
                        checked={otherWithdrawalType}
                        onChange={() => {
                          // if going to check, set default others value
                          handleOnChange(
                            'otherWithdrawalType',
                            !otherWithdrawalType ? 'Others Withdrawal' : '',
                          );
                        }}
                      />
                      {' '}
                      Others
                    </div>
                    <Form.Control
                      type="text"
                      value={otherWithdrawalType}
                      onChange={({ target }) => {
                        handleOnChange('otherWithdrawalType', target.value);
                      }}
                    />
                  </div>

                  <Form.Label>
                    Recipient Name
                    <span className="requiredField"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    className="mb-4"
                    onChange={({ target }) => {
                      handleOnChange('recipientName', target.value);
                    }}
                  />

                  <Form.Label>
                    Bank Account Number
                    <span className="requiredField"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="bankAccountNumber"
                    onChange={({ target }) => {
                      handleOnChange('bankAccountNumber', target.value);
                    }}
                    className="mb-4"
                  />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <div className="mb-4">
                <h2>Payments</h2>
              </div>
              <Form.Group className="mb-4">
                <Row>
                  <Col>
                    <div className="d-flex flex-column">
                      {paymentsOptions.map((item, index) => (
                        <div className="d-flex align-items-center mb-3">
                          <Form.Check
                            type="checkbox"
                            key={index}
                            value={item}
                            disabled={bankChecked}
                            onClick={handleCheckPayments}
                            onChange={handleCheckPayments}
                            checked={checkedPayments.includes(item)}
                          />
                          <span>
                            {item}
                          </span>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <div className="d-flex align-items-center mb-4">
                    <div className="checkbox__Container">
                      <Form.Check
                        type="checkbox"
                        value="Others"
                        checked={otherPayments}
                        onChange={() => {
                          // if going to check, set default others value
                          handleOnChange(
                            'otherPayments',
                            !otherPayments ? 'Others Payments' : '',
                          );
                        }}
                      />
                      {' '}
                      Others
                    </div>
                    <Form.Control
                      type="text"
                      value={otherPayments}
                      onChange={({ target }) => {
                        handleOnChange('otherPayments', target.value);
                      }}
                    />
                  </div>
                </Row>
              </Form.Group>
              <div className="commentBox">
                <Form.Label>Payment Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="..."
                  style={{ height: '100px' }}
                  onChange={({ target }) => {
                    handleOnChange('paymentNotes', target.value);
                  }}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      {/*  End of Money Trail */}
    </>
  );
}
OsshMoneytrail.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  issuingBank: PropTypes.string,
  inflowTrailType: PropTypes.array,
  otherInflowPartner: PropTypes.string,
  recipientBank: PropTypes.string,
  withdrawalTrailType: PropTypes.array,
  recipientName: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  p2p: PropTypes.array,
  otherPayments: PropTypes.string,
  payments: PropTypes.array,
  p2pNotes: PropTypes.string,
  paymentNotes: PropTypes.string,
  otherWithdrawalType: PropTypes.string,
};

OsshMoneytrail.defaultProps = {
  issuingBank: undefined,
  inflowTrailType: [],
  otherInflowPartner: undefined,
  otherPayments: undefined,
  recipientBank: undefined,
  withdrawalTrailType: [],
  recipientName: undefined,
  bankAccountNumber: undefined,
  p2p: [],
  payments: [],
  p2pNotes: undefined,
  paymentNotes: undefined,
  otherWithdrawalType: undefined,
};
export default OsshMoneytrail;
