/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { state, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';

function OsshTopUpDetails({
  handleOnChange,
  numberOfTopUps,
  topUpChannel,
  topUpBranch,
  totalTopUp,
  sameBranch,
  notSameBranch,
  otherTopUpInfo,
  isChecked,
  changeCheckBoxValue,
}) {
  const TopUpChannel = [
    ' ',
    '7-eleven CLiQQ',
    'AUB online/OTC Bills Payment',
    'Banco de Oro ATM',
    'Banco de Oro Over-the-Counter (within Metro Manila)',
    'Bank of Commerce',
    'Bayad Center',
    'BDO Internet Banking (Fund Transfer)',
    'BDO Network Bank (formerly known as ONB)',
    'BDO Over-the-Counter (Outside Manila)',
    'BPI (Total Count of 150,000 and below)',
    'BPI (Total Count of 150,001 to 200,000)',
    'BPI (Total Count of 200,001 and above)',
    'BPI ExpressOnline (Bills Payment)',
    'BPI ExpressOnline/Mobile (Fund Transfer)',
    'BPI Over-the-Counter (Bills Payment)',
    'Cebuana Lhuillier',
    'Cebuana Lhuillier (Bills Payment)',
    'Chinabank Online',
    'Chinabank Online Bills Payment',
    'Chinabank OTC Bills Payment',
    'Chinabank Over-the-Counter/ATM',
    'Coins.ph Wallet',
    'CVM Pawnshop',
    'DA5',
    'EastWest Bank Over-the-Counter',
    'ECPay',
    'ETap',
    'EXPay',
    'Expresspay',
    'GCash',
    'Globe GCash',
    'GrabPay',
    'Instapay',
    'Landbank ATM Online',
    'Landbank Over-the-Counter (Bills Payment)',
    'LBC',
    'M. Lhuillier',
    'MC International',
    'Metrobank Over-the-Counter',
    'Metrobankdirect',
    'ML Pay?',
    'MLhuillier',
    'Moneygment',
    'Multisys',
    'Palawan Pawnshop',
    'Pay with Paymaya',
    'Pay&Go',
    'PayMaya QR',
    'Perahub',
    'PNB Online (Bills Payment)',
    'PNB Over-the-Counter (Bills Payment)',
    'Posible',
    'Possible',
    'PSBank Online Banking',
    'Puregold',
    'RCBC AccessOne Online Banking',
    'RCBC Over-the-Counter (Bills Payment)',
    'RCBC Savings Over-the-Counter (Bills Payment)',
    'RD Pawnshop',
    'Robinsons Bank Online Banking Bills Payment',
    'Robinsons Bank Over-the-Counter Bills Payment',
    'Robinsons Dept Store',
    'Security Bank ATM (Bills Payment)',
    'Security Bank OTC (Bills Payment)',
    'SM Bills Payment',
    'SM Dept/Supermarket/Savemore Counter',
    'Stripe (<500)',
    'Stripe (>500)',
    'Touchpay',
    'True Money',
    'UCPB Connect Online banking',
    'UCPB Over-the-Counter/ATM (Bills Payment)',
    'UCPB Over-the-Counter/ATM Bills Payment',
    'Unionbank',
    'Unionbank Internet Banking',
    'Unionbank OTC/ATM/Online (Bills Payment)',
    'USSC',
    'Villarica Pawnshop',
    'Visa and MC Domestic, JCB Domestic & International',
    'Visa Internationak',
    'ZoomPay',
  ];

  const optionsTopUpChannel = TopUpChannel.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  return (
    <>
      {/* Top-up Details */}
      <div className="ossh__Card ossh__Card--topUpDetails">
        <div className="heading">
          <div className="title">
            <h1>Top-up Details</h1>
          </div>
          <div className="checkbox__Container">
            <Form.Check
              type="checkbox"
              label="N/A"
              checked={isChecked}
              onChange={changeCheckBoxValue}
            />
          </div>
        </div>

        <Row>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>
                Number of Top-ups
                <span className="requiredField"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                disabled={isChecked}
                onChange={({ target }) => {
                  handleOnChange('numberOfTopUps', target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>Top-up Channel</Form.Label>
              <Form.Select
                required
                disabled={isChecked}
                onChange={({ target }) => {
                  handleOnChange('topUpChannel', target.value);
                }}
              >
                {optionsTopUpChannel}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-4">
              <Form.Label>Top-up Branch (if available)</Form.Label>
              <Form.Control
                type="text"
                disabled={isChecked}
                onChange={({ target }) => {
                  handleOnChange('topUpBranch', target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form className="mb-4">
              <Form.Label>
                Total Amount of Top-ups
                <span className="requiredField"> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                disabled={isChecked}
                onChange={({ target }) => {
                  handleOnChange('totalTopUp', target.value);
                }}
              />
            </Form>
          </Col>
        </Row>

        <Row>
          <Form>
            <Form.Label>Same Branch for All</Form.Label>
            {['radio'].map((type) => (
              <div key={`inline-${type}`} className="mb-4 d-flex">
                <Form.Check
                  inline
                  label="Yes"
                  name="same-branch"
                  type={type}
                  id={`inline-${type}-1`}
                  disabled={isChecked}
                  value
                  onChange={({ target }) => {
                    handleOnChange('sameBranch', true); // value attribute only accepts string
                  }}
                />
                <Form.Check
                  inline
                  label="No"
                  name="same-branch"
                  type={type}
                  id={`inline-${type}-2`}
                  disabled={isChecked}
                  value={false}
                  onChange={({ target }) => {
                    handleOnChange('sameBranch', false);
                  }}
                />
              </div>
            ))}
          </Form>
        </Row>

        <Row>
          <div className="commentBox">
            <Form.Label>Other Pertinent Top-up Information</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="..."
              style={{ height: '100px' }}
              disabled={isChecked}
              onChange={({ target }) => {
                handleOnChange('otherTopUpInfo', target.value);
              }}
            />
          </div>
        </Row>
      </div>
      {/* End of Top-up Details */}
    </>
  );
}

OsshTopUpDetails.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  numberOfTopUps: PropTypes.string,
  topUpChannel: PropTypes.string,
  topUpBranch: PropTypes.string,
  totalTopUp: PropTypes.string,
  sameBranch: PropTypes.bool,
  notSameBranch: PropTypes.string,
  otherTopUpInfo: PropTypes.string,
};

OsshTopUpDetails.defaultProps = {
  numberOfTopUps: undefined,
  topUpChannel: undefined,
  topUpBranch: undefined,
  totalTopUp: undefined,
  sameBranch: undefined,
  notSameBranch: undefined,
  otherTopUpInfo: undefined,
};

export default OsshTopUpDetails;
