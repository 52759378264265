/* eslint-disable react/button-has-type */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import OsshKYCDeals from './reusables/OsshKYCDeals';
import OsshLinkageCheck from './reusables/OsshLinkageCheck';
import OsshTopUpDetails from './reusables/OsshTopUpDetails';
import OsshMoneytrail from './reusables/OsshMoneyTrail';
import OsshHistory from './reusables/OsshHistory';
import OsshPresence from './reusables/OsshPresence';
import OsshDecision from './reusables/OsshDecision';
import OsshOthers from './reusables/OsshOthers';
import AppContext from './reusables/AppContext';
import OsshHeader from './reusables/OsshHeader';

function Ossh() {
  const [inputData, setinputData] = useState({
    fullName: null,
    createDateTime: null,
    monthlyInflow: null,
    natureOfWork: null,
    sourceOfFunds: null,
    numberOfTopUps: null,
    naChecked: null,
    topUpChannel: null,
    topUpBranch: null,
    totalTopUp: null,
    sameBranch: null,
    otherTopUpInfo: null,
    issuingBank: null,
    inflowTrailType: [], // newly added field
    withdrawalTrailType: [],
    otherInflowPartner: null, // dynamic inflow trail type apart from ^
    recipientBank: null,
    recipientName: null,
    bankAccountNumber: null,
    p2p: [],
    payments: [],
    otherPayments: null,
    paymentNotes: null,
    p2pNotes: null,
    decision: null,
    otherPertinentInfo: null,
    uid: [],
    walletHistory: null,
    walletHistoryDate: null,
    walletHistoryViolation: null,
    sphEscalation: null,
    sphEscalationDate: null,
    sphEscalationViolation: null,
    onlinePresence: [],
    bnrs: [],
    walletHistoryType: [],
    completedInvestigations: null,
    completedInvestigationsType: [],
    sphEscalationType: [],
    otherWithdrawalType: null,
  });

  const {
    fullName,
    createDateTime,
    monthlyInflow,
    natureOfWork,
    sourceOfFunds,
    numberOfTopUps,
    topUpChannel,
    naChecked,
    topUpBranch,
    totalTopUp,
    sameBranch,
    otherTopUpInfo,
    issuingBank,
    inflowTrailType, // newly added field
    otherInflowPartner,
    recipientBank,
    recipientName,
    withdrawalTrailType,
    otherWithdrawalType,
    bankAccountNumber,
    p2p,
    otherPayments,
    payments,
    p2pNotes,
    paymentNotes,
    decision,
    otherPertinentInfo,
    uid,
    walletHistory,
    walletHistoryType,
    completedInvestigations,
    completedInvestigationsType,
    sphEscalation,
    sphEscalationType,
    onlinePresence,
    bnrs,
    inputListInvestigation,
  } = inputData;

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [visible, showResults] = useState(false);
  const [validated, setValidated] = useState(false);
  const { user } = useContext(AppContext);

  const kyc_details = {
    name: inputData.fullName,
    created_at: inputData.createDateTime,
    monthly_inflow: inputData.monthlyInflow,
    nature_of_work: inputData.natureOfWork,
    source_of_funds: inputData.sourceOfFunds,
  };

  const top_up_details = {
    number_of_top_ups: inputData.numberOfTopUps,
    same_branch_all: inputData.sameBranch,
    top_up_channel: inputData.topUpChannel,
    top_up_branch: inputData.topUpBranch,
    total_amount_top_ups: inputData.totalTopUp,
    other_top_up_info: inputData.otherTopUpInfo,
  };

  const inflow_trail = {
    issuing_bank: inputData.issuingBank,
    type: [
      ...inputData.inflowTrailType,
      // include other value ONLY if type is not n/a selected OR if other value is null
      (inputData.inflowTrailType.includes('N/A') || !inputData.otherInflowPartner)
        ? ''
        : inputData.otherInflowPartner,
    ],
  };
  const withdraw_trail = {
    recipient_name: inputData.recipientName,
    recipient_bank: inputData.recipientBank,
    bank_acount_number: inputData.bankAccountNumber,
    type: [...inputData.withdrawalTrailType, inputData.otherWithdrawalType],
  };

  const p2p_trail = {
    note: inputData.p2pNotes,
    type: inputData.p2p,
  };

  const payments_trail = {
    note: inputData.paymentNotes,
    type: [...inputData.payments, inputData.otherPayments || ''],
  };

  const linkage_check = {
    linked_users: inputData.uid?.filter(({ uidInput }) => uidInput), // filter only those with valid uids
  };

  const wallet_history = {
    wallet_violation_info: inputData.walletHistory,
    type: inputData?.walletHistory?.toLowerCase() === 'existing wallet violation'
      ? inputData.walletHistoryType
      : [],
  };

  const completed_investigation = {
    previous_flagged_notes: inputData.completedInvestigations,
    type: inputData?.completedInvestigations?.toLowerCase() === 'previously flagged'
      ? inputData.completedInvestigationsType
      : [],
  };

  const sph_escalation = {
    sph_violation_info: inputData.sphEscalation,
    type: inputData?.sphEscalation?.toLowerCase() === 'existing sph escalation'
      ? inputData.sphEscalationType
      : [],
  };

  const shopee_shop_link = {
    findings: inputData.bnrs,
  };

  const online_presence = {
    findings: inputData.onlinePresence,
  };

  const remarks = {
    decision: inputData.decision?.toUpperCase(),
    kyc_details,
    top_up_details: !isChecked ? top_up_details : {},
    inflow_trail,
    withdraw_trail,
    p2p_trail,
    payments_trail,
    linkage_check,
    wallet_history,
    completed_investigation,
    sph_escalation,
    shopee_shop_link,
    online_presence,
    other_information: inputData.otherPertinentInfo,
  };

  const queryParams = new URLSearchParams(window.location.search);
  const caseId = queryParams.get('id');

  const showReport = () => {
    alert('Summary generated');
    showResults(true);
    setTimeout(() => {
      // scroll to report
      document.querySelector('.ossh__Card--GeneratedSummary').scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
  };

  const handleSubmit = () => {
    setLoadingSubmit(true);
    axios.post('/api/add_user_case_remark', {
      user_case_id: caseId,
      remarks,
    }, {
      headers: {
        id_token: user?.accessToken,
      },
    }).then(() => {
      setLoadingSubmit(false);
      alert('Successfully Submitted');
      window.location.href = `/remarks/${caseId}`;
    }).catch(() => {
      setLoadingSubmit(false);
      alert('Something went wrong. Please contact BZT. We apologize for the inconvenience');
    });
  };

  const handleSetinputData = (key, value) => {
    const cloned = { ...inputData };
    cloned[key] = value;
    setinputData(cloned);
  };

  const clear = () => {
    window.location.reload(false);
  };

  let isFormValid = false;

  const requiredFields = (
    fullName
    && monthlyInflow > 0
    && createDateTime
    && natureOfWork
    && sourceOfFunds
    && ((numberOfTopUps && totalTopUp) || isChecked)
    && decision

    // Inflow Validation //
    && (
      (inflowTrailType.length > 0 && (inflowTrailType.includes('Instapay')
        || inflowTrailType.includes('DragonPay')
        || inflowTrailType.includes('Direct Debit')
      )) ? issuingBank : true // if either of those checkboxes are selected, issuingbank cannot be empty
    )

    // Histories Validations //
    && (
      // these fields can either be BOTH files or BOTH empty
      (recipientName && bankAccountNumber)
      || (!recipientName && !bankAccountNumber)
    )
    && (
      walletHistory && (
        walletHistory === 'Existing Wallet Violation' // if choose existing, require more than 0 history and >>>>>>>>>
          ? (walletHistoryType.length > 0 && !walletHistoryType?.some(({ date, violation }) => (!date || !violation))) // all fields must be filled
          : true
      )
    )
    && (
      sphEscalation && (
        sphEscalation === 'Existing SPH escalation' // if choose existing, require more than 0 history and >>>>>>>>>
          ? (sphEscalationType.length > 0 && !sphEscalationType?.some(({ date, violation }) => (!date || !violation))) // all fields must be filled
          : true
      )
    )
    && (
      completedInvestigations === 'Previously Flagged'
        ? (completedInvestigationsType.length > 0 && !completedInvestigationsType?.some(({ date, flag }) => (!date || !flag)))
        : true
    )

    // Inflows Validation //
    && (!(p2p.length === 1 && p2p[0] === '')) // others p2p works differently but logic should be same as ^

    // Online Presence + BNRs Validation //
    && !onlinePresence?.some(({ findings, link }) => (
      (!findings && !link) // both fields empty -> OK
        ? false // -> If either are not filled, return error
        : (!findings || !link)
    ))
    && !bnrs?.some(({ findings, link }) => (
      (!findings && !link)
        ? false
        : (!findings || !link)
    ))

    // Linkage check
    && !uid?.some(({ uidInput, uidType, uidInputEntityLinkage }) => (
      (uidInput !== '' // if UID is typed, there should at least be one checkbox checked
        ? uidType.length === 0
        : !(!uidInput && uidType.length === 0 && !uidInputEntityLinkage)) // if all fields empty OK (ignored)
    ))
  );

  if (requiredFields) {
    isFormValid = true;
  }

  const changeCheckBoxValue = (e) => {
    const val = e.target.value;
    setIsChecked((state) => !state);
  };

  const withdrawaltrailtype = withdrawalTrailType.join(',   ');
  const inflowtrailtype = inflowTrailType.join(',   ');
  const payment = payments.join(',   ');

  return (
    <div>
      <OsshHeader />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <OsshKYCDeals
          handleOnChange={handleSetinputData}
          fullName={inputData.fullName}
          monthlyInflow={inputData.monthlyInflow}
          createDateTime={inputData.createDateTime}
          natureOfWork={inputData.natureOfWork}
          sourceOfFunds={inputData.sourceOfFunds}
        />

        <OsshTopUpDetails
          handleOnChange={handleSetinputData}
          numberOfTopUps={inputData.numberOfTopUps}
          topUpChannel={inputData.topUpChannel}
          topUpBranch={inputData.topUpBranch}
          totalTopUp={inputData.totalTopUp}
          sameBranch={inputData.sameBranch}
          otherTopUpInfo={inputData.otherTopUpInfo}
          isChecked={isChecked}
          changeCheckBoxValue={changeCheckBoxValue}
        />
        <OsshMoneytrail
          handleOnChange={handleSetinputData}
          issuingBank={inputData.issuingBank}
          inflowTrailType={inputData.inflowTrailType}
          withdrawalTrailType={inputData.withdrawalTrailType}
          otherWithdrawalType={inputData.otherWithdrawalType}
          otherInflowPartner={inputData.otherInflowPartner}
          recipientBank={inputData.recipientBank}
          p2p={inputData.p2p}
          otherPayments={inputData.otherPayments}
          payments={inputData.payments}
          p2pNotes={inputData.p2pNotes}
          paymentNotes={inputData.paymentNotes}
        />

        <OsshLinkageCheck
          handleOnChange={handleSetinputData}
          uid={inputData.uid}
        />

        <OsshHistory
          handleOnChange={handleSetinputData}
          walletHistory={inputData.walletHistory}
          walletHistoryType={inputData.walletHistoryType}
          completedInvestigations={inputData.completedInvestigations}
          completedInvestigationsType={inputData.completedInvestigationsType}
          sphEscalation={inputData.sphEscalation}
          sphEscalationType={inputData.sphEscalationType}
        />

        <OsshPresence
          handleOnChange={handleSetinputData}
          onlinePresence={inputData.onlinePresence}
          bnrs={inputData.bnrs}
        />

        <OsshDecision
          handleOnChange={handleSetinputData}
          decision={inputData.decision}
        />

        <OsshOthers
          handleOnChange={handleSetinputData}
          otherPertinentInfo={inputData.otherPertinentInfo}
        />
      </Form>

      <div className="ossh__gen-report d-flex justify-content-end mb-5">
        <div className="mr-2">
          <Button className="btn btn-secondary" variant="Light" onClick={clear}>
            Reset
          </Button>
        </div>

        <Button
          type="submit"
          onClick={showReport}
          disabled={!isFormValid}
        >
          Generate Reports
        </Button>
      </div>

      {visible && (
        <div className="ossh__Card ossh__Card--GeneratedSummary">
          <div className="mb-5">
            <h1>Generated Summary </h1>
          </div>

          <div className="mb-5">
            <div className="mb-2">
              <h2> KYC Deals </h2>
            </div>
            <div className="mb-2">
              <i>Name:</i>
              {' '}
              {fullName}
            </div>
            <div className="mb-2">
              <i>Inflow:</i>
              &nbsp;
              {monthlyInflow}
            </div>
            <div className="mb-2">
              <i>Create Time:</i>
              &nbsp;
              {createDateTime}
            </div>
            <div className="mb-2">
              <i>Nature of Work:</i>
              {' '}
              &nbsp;
              {natureOfWork}
            </div>
            <div className="mb-2">
              <i>Source of funds:</i>
              {' '}
              &nbsp;
              {sourceOfFunds}
            </div>
          </div>

          { !isChecked // only if n/a not checked
            && (
            <div className="mb-5">
              <div className="mb-2">
                <h2> Top-up details </h2>
              </div>
              <div className="mb-2">
                <i>Number of Top-ups:</i>
                &nbsp;
                {numberOfTopUps}
              </div>
              <div className="mb-2">
                <i>Top-Up Channel:</i>
                &nbsp;
                {topUpChannel}
              </div>
              <div className="mb-2">
                <i>Top-Up Branch:</i>
                &nbsp;
                {topUpBranch}
              </div>
              <div className="mb-2">
                <i>Total Amount of Top-ups:</i>
                &nbsp;
                {totalTopUp}
              </div>
              <div className="mb-2">
                <i>Same Branch for All:</i>
                &nbsp;
                {sameBranch}
              </div>
              <div className="mb-2">
                <i>Other Pertinent Top-up Information:</i>
                {' '}
                &nbsp;
                {otherTopUpInfo}
              </div>
            </div>
            )}

          <div className="mb-5">
            <div className="mb-2">
              <h2> Money Trail </h2>
            </div>
            <div className="mb-2">
              <i>Inflow Issuing Bank:</i>
              &nbsp;
              {issuingBank}
            </div>
            <div className="mb-2">
              <i>Inflow Issuing Type:</i>
              &nbsp;
              {inflowtrailtype}
            </div>
            <div className="mb-2">
              <i>Inflow Recipient Bank:</i>
              &nbsp;
              {recipientBank}
              {' '}
              <br />
            </div>
            <div className="mb-2">
              <i>Withdrawal Recipient Name:</i>
              &nbsp;
              {recipientName}
            </div>
            <div className="mb-2">
              <i>Withdraw Trail Type:</i>
              &nbsp;
              {withdrawaltrailtype}
            </div>
            <div className="mb-2">
              <i>Withdrawal Bank Account Number:</i>
              &nbsp;
              {bankAccountNumber}
            </div>
            <div className="mb-2">
              <i>P2P:</i>
              &nbsp;
              {p2p?.join(', ')}
            </div>
            <div className="mb-2">
              <i>P2P Notes:</i>
                &nbsp;
              {p2pNotes}
            </div>
            <div className="mb-2">
              <i>Payments:</i>
              &nbsp;
              {payment}
            </div>
            <div className="mb-2">
              <i>Payment Notes:</i>
              &nbsp;
              {paymentNotes}
            </div>
          </div>

          <div className="mb-5">
            <div className="mb-2">
              <h2> Linkage Check </h2>
            </div>
            <div className="mb-2 linkage-check">
              {uid.map(({ uidInput, uidType, uidInputEntityLinkage }) => (
                <>
                  <p>
                    <i>UID:</i>
                    {' '}
                    {uidInput}
                    {' '}
                  </p>
                  <p>
                    <i>Type:</i>
                    {' '}
                    {uidType.join(', ')}
                    {' '}
                  </p>
                  <p>
                    <i>Entity Linkage:</i>
                    {' '}
                    {uidInputEntityLinkage}
                    {' '}
                  </p>
                  <hr />
                </>
              ))}
            </div>
          </div>

          <div className="mb-5">
            <div className="mb-2">
              <h2>History</h2>
            </div>
            <div className="mb-2">
              <i><b>Wallet History:</b></i>
              {' '}
              {walletHistory}
            </div>
            <div>
              {walletHistoryType.map(({ date, violation }) => (
                <>
                  <p key={date}>
                    <i>Date:</i>
                    {' '}
                    {date}
                  </p>
                  <p>
                    <i>Violation:</i>
                    {' '}
                    {violation}
                    {' '}
                  </p>
                  <hr />
                </>
              ))}
            </div>
            <div className="mb-5">
              <div className="mb-2">
                <i><b>Completed Investigation:</b></i>
                {' '}
                {completedInvestigations}
              </div>
              {completedInvestigationsType.map(({ date, flag }) => (
                <>
                  <p key={date}>
                    <i>Date:</i>
                    {date}
                  </p>
                  <p>
                    <i>Flag:</i>
                    {flag}
                  </p>
                  <hr />
                </>
              ))}
            </div>
            <div className="mb-5">
              <div className="mb-2">
                <i><b>SPH Escalation:</b></i>
                {' '}
                {sphEscalation}
              </div>
              {sphEscalationType.map(({ date, violation }) => (
                <>
                  <p key={date}>
                    <i>Date:</i>
                    {' '}
                    {date}
                  </p>
                  <p>
                    <i>Violation:</i>
                    {' '}
                    {violation}
                    {' '}
                  </p>
                  <hr />
                </>
              ))}
            </div>
          </div>

          <div className="mb-5">
            <div className="mb-2">
              <i><b>Online Presence:</b></i>
            </div>
            <div className="mb-4">
              {onlinePresence.map(({ link, findings }) => (
                <>
                  <p key={link}>
                    <i>Link:</i>
                    {' '}
                    {link}
                  </p>
                  <p>
                    <i>Findings:</i>
                    {' '}
                    {findings}
                    {' '}
                  </p>
                  <hr />
                </>
              ))}
            </div>
            <div className="mb-2">
              <i><b>BNRS:</b></i>
            </div>
            <div className="mb-4">
              {bnrs.map(({ link, findings }) => (
                <>
                  <p key={link}>
                    <i>Link:</i>
                    {' '}
                    {link}
                  </p>
                  <p>
                    <i>Findings:</i>
                    {' '}
                    {findings}
                    {' '}
                  </p>
                  <hr />
                </>
              ))}
            </div>
          </div>

          <div className="mb-5">
            <div className="mb-2">
              <h2> Decision </h2>
            </div>
            <div className="mb-2">
              {decision.replace('_', ' ').toUpperCase()}
            </div>
          </div>

          <div className="mb-5">
            <div className="mb-2">
              <h2> Other Pertinent Information </h2>
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>
              {otherPertinentInfo}
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={!isFormValid || loadingSubmit}
            >
              {loadingSubmit ? <Spinner animation="border" /> : 'Submit Notes'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Ossh;
