import { Table, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TableC({ data, order, loading }) {
  const headers = order || (
    data.length > 0
      ? Object.keys(data[0])
      : []
  );

  return (
    <div>
      <Table>
        <div className={`table__loader ${loading ? 'loading' : ''}`}>
          <Spinner animation="border" />
        </div>
        <thead>
          <tr>
            {headers.map((header) => <th>{ header }</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((dataTr) => (
            <tr
              key={`tr-${dataTr['Case ID'].data}`}
            >
              { headers.map((header) => (
                <td
                  style={dataTr[header].style || {}}
                  className={dataTr[header].className || ''}
                  key={`td-${dataTr['Case ID'].data}-${header}`}
                >
                  { dataTr[header].data }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

TableC.propTypes = {
  order: PropTypes.array,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

TableC.defaultProps = {
  order: [],
};

export default TableC;
