import React from 'react';
import PropTypes from 'prop-types';

import Pending from '../../assets/images/pending.png';
import NonFraud from '../../assets/images/non-fraud.png';
import Fraud from '../../assets/images/fraud.png';
import PossibleFraud from '../../assets/images/possible-fraud.png';
import Monitoring from '../../assets/images/monitoring.png';
import Flagging from '../../assets/images/flagging.png';

function DecisionLogo({
  decision,
}) {
  let toUse;

  switch (decision?.toLowerCase()) {
    case 'pending':
      toUse = Pending;
      break;
    case 'not_fraud':
      toUse = NonFraud;
      break;
    case 'confirmed_fraud':
      toUse = Fraud;
      break;
    case 'possible_fraud':
      toUse = PossibleFraud;
      break;
    case 'for_flagging':
      toUse = Flagging;
      break;
    case 'for_monitoring':
      toUse = Monitoring;
      break;
    default:
      toUse = NonFraud;
  }

  return (
    <div className={`decisionLogo--${decision?.toLowerCase()}`}>
      <img src={toUse} alt={decision} />
      <span>
        {' '}
        { decision?.replace('_', ' ') }
        {' '}
      </span>
    </div>
  );
}

DecisionLogo.propTypes = {
  decision: PropTypes.string,
};

DecisionLogo.defaultProps = {
  decision: 'not_fraud',
};

export default DecisionLogo;
